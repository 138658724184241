import styles from "./result-table-styles.module.css";

const ResultTableRight = ({
    showDetails,
    details,
    checkboxes,
    tips,
    exploreText,
}) => {
    return (
        <div className={styles.rightSide}>
            {showDetails ? (
                <div className={styles.details}>{details}</div>
            ) : (
                <>
                    <div className={styles.checks}>
                        <h5>Further selection:</h5>

                        <div>{checkboxes}</div>
                    </div>

                    <div className={`${styles.tableTip} ${styles.tableCenter}`}>
                        <img
                            className={styles.tableTipImg}
                            src={"/images/arrow-icon.svg"}
                            alt={""}
                        />
                        <p className={styles.tableTipInfo}>{exploreText}</p>
                    </div>

                    <div className={styles.tips}>{tips}</div>
                </>
            )}
        </div>
    );
};
export default ResultTableRight;
