import styles from "./deal-taxes-styles.module.css";

const DealTaxesDetailRow = ({ rowTitle, data, isActive, legendChange }) => {
    const handleTipEnter = (tip) => {
        legendChange(tip.legendId);
    };

    const handleMouseLeave = () => {
        legendChange(null);
    };

    return (
        <div className={`${styles.row} ${isActive ? styles.active : ""}`}>
            <h5 className={styles.rowTitle}>{rowTitle}</h5>
            <div className={styles.rowData}>
                {data.map((d, index) => {
                    return (
                        <div
                            key={index}
                            className={styles.group}
                            onMouseEnter={() => handleTipEnter(d)}
                            onMouseLeave={handleMouseLeave}
                        >
                            <h6 className={styles.groupTitle}>{d.tax}</h6>
                            <span className={styles.groupValue}>
                                {`${d.percent}%`}
                            </span>
                            <span
                                className={`${styles.groupValue} ${styles.groupValueText}`}
                            >
                                {`of ${d.taxBase}`}
                                <span className={styles.legend}>
                                    {d.legendId}
                                </span>
                            </span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default DealTaxesDetailRow;
