import { linkVertical } from "d3-shape";
import { max, scaleBand, scaleLinear } from "d3";
import {
    getF12,
    getF15,
    getF9,
    getT1,
    getT10,
    getT11,
    getT12,
    getT2,
    getT3,
    getT4,
    getT5,
    getT6,
    getT7,
    getT8,
    getT9,
    getV1F5,
    getV2F7,
    getV4F10,
    getV6F11,
    getV7F16,
    getV8F14,
} from "../../pages/DealTaxesExpanded/utils";

const SankeyCustom = (data) => {
    const levels = ["investor", "fund", "asset"];
    const categories = ["investment", "holding", "distribution", "selling"];

    const width = 1000;
    const height = 833;
    const margin = { top: 100, bottom: 100, left: 100, right: 0 };

    let nodes = [];
    let tips = [];
    let links = [];
    let xAxis = [];
    let yAxis = [];
    let lines = [];
    let bottomLines = [];

    const createPath = linkVertical()
        .source((d) => d.source)
        .target((d) => d.target);

    const getLinkWidth = (node, tip) => {
        let width = (node.width / 100) * tip.value;

        if (width < 1.5) {
            return 2;
        }
        return width;
    };

    const createLink = (sourceNode, targetNode) => {
        const offset = sourceNode.width - targetNode.width;

        let link = {
            path: createPath({
                source: [
                    sourceNode.x + (targetNode.width / 2 + offset),
                    sourceNode.y + sourceNode.height / 2,
                ],
                target: [
                    targetNode.x + targetNode.width / 2,
                    targetNode.y + targetNode.height / 2,
                ],
            }),
            color: "#7d9dd8",
            width: targetNode.width,
            opacity: 0.4,
            class: `${sourceNode.category}`,
        };

        if (
            sourceNode.level === "investor" &&
            sourceNode.category === "holding"
        ) {
            link.path = createPath({
                source: [
                    sourceNode.x + sourceNode.width / 2,
                    sourceNode.y + sourceNode.height / 2,
                ],
                target: [
                    targetNode.x + targetNode.width / 2,
                    targetNode.y + targetNode.height / 2,
                ],
            });
        }

        links.push(link);
    };

    const createTip = (
        sourceNode,
        targetNode,
        tipData,
        align,
        direction,
        offset,
        overlapping
    ) => {
        const tipWidth =
            tipData.tax.trim().length < 12
                ? 100
                : tipData.tax.trim().length * 6 + 10;

        let tipX;

        if (align < 0) {
            tipX = targetNode.x + targetNode.width - 60;
        } else {
            if (
                targetNode.level === "investor" &&
                targetNode.category === "selling"
            ) {
                tipX = targetNode.x - (tipWidth / 2 + 20);
            } else if (
                targetNode.level === "investor" &&
                targetNode.category === "distribution"
            ) {
                tipX = targetNode.x - (tipWidth / 2 + 80);
            } else {
                tipX = targetNode.x - (tipWidth / 2 + 20);
            }
        }

        const yOffset = offset ? 100 : 200;

        let tipY =
            direction > 0
                ? targetNode.y + (offset ? 50 : 90)
                : targetNode.y - yOffset;

        if (overlapping) {
            tipX += 70;
            tipY += 70;
        }

        const tip = {
            x: tipX,
            y: tipY,
            width: tipWidth,
            height: 65,
            value: tipData.value,
            tax: tipData.tax,
            taxBase: tipData.taxBase,
            percent: tipData.percent,
            category: sourceNode.category,
        };

        if (tipData.fullComments && tipData.fullComments.length > 0) {
            tip.fullComments = tipData.fullComments;
        } else {
            tip.fullComments = null;
        }

        const tipArrowPosition = {
            source:
                direction > 0
                    ? [tip.x + tip.width / 2, tip.y - 25]
                    : [tip.x + tip.width / 2, tip.y + tip.height + 25],
            target:
                direction > 0
                    ? [tip.x + tip.width / 2, tip.y - 10]
                    : [tip.x + tip.width / 2, tip.y + tip.height + 10],
        };

        const tipArrow = {
            path: createPath(tipArrowPosition),
            source: tipArrowPosition.source,
            target: tipArrowPosition.target,
            color: "#ff7400",
            width: getLinkWidth(sourceNode, tip),
            opacity: 1,
            class: `${sourceNode.category} tip-arrow`,
        };

        let linkSource =
            align > 0
                ? [
                      sourceNode.x + tipArrow.width / 2,
                      sourceNode.y + sourceNode.height / 2,
                  ]
                : [
                      sourceNode.x + (sourceNode.width + tipArrow.width / 2),
                      sourceNode.y + sourceNode.height / 2,
                  ];

        if (
            targetNode.level === "investor" &&
            targetNode.category === "holding"
        ) {
            linkSource =
                align > 0
                    ? [
                          sourceNode.x + tipArrow.width / 2 - 10,
                          sourceNode.y + sourceNode.height,
                      ]
                    : [
                          sourceNode.x +
                              (sourceNode.width + tipArrow.width / 2) +
                              10,
                          sourceNode.y + sourceNode.height,
                      ];
        }

        const linkToTip = {
            path: createPath({ source: linkSource, target: tipArrow.source }),
            source: [
                sourceNode.x + tipArrow.width / 2,
                sourceNode.y + sourceNode.height / 2,
            ],
            target: tipArrow.source,
            color: "#ff5900",
            width: getLinkWidth(sourceNode, tip),
            opacity: 0.4,
            class: `${sourceNode.category} tip-link`,
        };

        let triangleLink;

        const triangleLinkTarget =
            align > 0
                ? [
                      sourceNode.x + tipArrow.width / 2 - 5,
                      sourceNode.y + sourceNode.height - 40,
                  ]
                : [
                      sourceNode.x +
                          (sourceNode.width + tipArrow.width / 2) +
                          5,
                      sourceNode.y + sourceNode.height - 40,
                  ];

        if (
            targetNode.level === "investor" &&
            targetNode.category === "holding"
        ) {
            triangleLink = {
                path: createPath({
                    source: linkSource,
                    target: triangleLinkTarget,
                }),
                source: linkSource,
                target: triangleLinkTarget,
                color: "#ff5900",
                width: getLinkWidth(sourceNode, tip),
                opacity: 0.4,
                class: `${sourceNode.category} tip-link`,
                type: "triangle",
            };
        }

        tips.push(tip);
        links.push(tipArrow, linkToTip);
        if (triangleLink) {
            links.push(triangleLink);
        }
    };

    const generateDefaultData = () => {
        let data = [];
        for (const level of levels) {
            for (const category of categories) {
                const node = {
                    x: 0,
                    y: 0,
                    width: 0,
                    height: 18,
                    level: level,
                    category: category,
                    value: 0,
                };

                if (level === "asset") node.y = height - margin.bottom - 40;
                if (level === "investor") node.y = margin.top;
                if (level === "fund") node.y = height - height / 2 - 40;

                if (category === "holding") node.value = 120;
                if (category === "distribution") node.value = 10;
                if (category === "investment") node.value = 100;
                if (category === "selling") node.value = 120;

                data.push(node);
            }
        }

        const xScale = scaleLinear()
            .domain([0, max(data, (d) => d.value)])
            .range([3, 140]);

        const xPosition = scaleBand()
            .domain(categories)
            .range([margin.left, width - margin.right]);

        const result = data.map((d) => {
            d.width = xScale(d.value);
            d.x =
                xPosition(d.category) - d.width / 2 + xPosition.bandwidth() / 2;

            if (d.category === "holding") {
                d.x += 40;
            }

            if (d.category === "distribution") {
                d.x += 40;
            }

            return d;
        });

        nodes = result;
    };

    const getNode = (category, level) => {
        return nodes.find((d) => d.category === category && d.level === level);
    };

    const getNodes = (category) => {
        return {
            investorNode: getNode(category, "investor"),
            fundNode: getNode(category, "fund"),
            assetNode: getNode(category, "asset"),
        };
    };

    const filterData = (category, levels) => {
        return data.filter(
            (ad) =>
                ad.eventCategory.trim() === category &&
                levels.indexOf(ad.level) > -1
        );
    };

    const valueToFixed = (value) => {
        value = Number.parseFloat(value).toFixed(2);

        const arr = value.split(".");

        if (arr[1] === "00") {
            return arr[0];
        }

        return parseFloat(value);
    };

    const createLines = () => {
        for (const node of nodes) {
            let x1 = node.x - 15;
            let x2 = node.x + node.width + 15;
            // if (node.level === "fund") {
            //     x1 = 120;
            //     x2 = width;
            // }

            x1 = 140;
            x2 = width - 20;

            lines.push({
                x1,
                x2,
                y: node.y + node.height,
            });

            bottomLines.push({
                x1,
                x2,
                y: node.y + node.height + 4,
                level: node.level,
                category: node.category,
            });
        }
    };

    const createTips = (
        filteredData,
        value,
        targetNode,
        createFirstTip,
        createSecondTip
    ) => {
        filteredData.sort((a, b) => {
            return parseFloat(b.taxRate) - parseFloat(a.taxRate);
        });

        for (const [index, tipData] of filteredData.entries()) {
            if (
                (tipData.level === "investor" &&
                    tipData.eventCategory === "holding") ||
                tipData.eventCategory === "selling"
            ) {
                if (tipData.taxBase !== "NAV") {
                    const { fundNode } = getNodes(tipData.eventCategory);

                    // T12 formula ?

                    if (tipData.eventCategory === "holding") {
                        tipData.value =
                            (parseFloat(fundNode.value) - 100) *
                            parseFloat(tipData.taxRate);
                    } else {
                        tipData.value =
                            (parseFloat(fundNode.value) - 100) *
                            parseFloat(tipData.taxRate);
                    }
                } else {
                    tipData.value = parseFloat(tipData.taxRate) * value;
                }
            } else {
                tipData.value = parseFloat(tipData.taxRate) * value;
            }

            tipData.percent = valueToFixed(parseFloat(tipData.taxRate));

            if (tipData.percent.toString().length > 10) {
                tipData.percent = tipData.percent.toFixed(2);
            }

            if (index === 0) {
                if (createFirstTip) {
                    createFirstTip(tipData);
                } else {
                    createTip(targetNode, targetNode, tipData, 1, 1);
                }
            } else {
                if (createSecondTip) {
                    createSecondTip(tipData);
                } else {
                    createTip(
                        targetNode,
                        targetNode,
                        tipData,
                        -1,
                        1,
                        false,
                        true
                    );
                }
            }

            if (value > targetNode.value) {
                targetNode.width -= (targetNode.width / 100) * tipData.value;
            } else {
                if (
                    targetNode.level !== "investor" &&
                    targetNode.category !== "holding"
                ) {
                    targetNode.width +=
                        (targetNode.width / 100) * tipData.value;
                }
            }

            if (index === 1) {
                break;
            }
        }
    };

    const calculateInvestmentFund = (data) => {
        const filteredData = filterData("investment", ["investor"]);
        const investorNode = getNode("investment", "investor");

        const fundNode = getNode("investment", "fund");

        if (filteredData.length === 0) {
            return;
        }

        const vv1f5 = getV1F5(data);
        fundNode.value = vv1f5.value;

        const t1 = getT1(data);

        createTip(investorNode, investorNode, t1.tip, 1, 1);
    };

    const calculateInvestmentAsset = (data) => {
        const filteredData = filterData("investment", ["asset", "fund"]);

        const { investorNode, fundNode, assetNode } = getNodes("investment");

        const f6 = getV2F7(data);
        assetNode.value = f6.value;
        assetNode.width = (assetNode.width * fundNode.value) / assetNode.value;

        if (filteredData.length === 0) {
            createLink(investorNode, fundNode);
            createLink(fundNode, assetNode);
            return;
        }

        const t2 = getT2(data);
        const t3 = getT3(data);

        if (t2.tip) {
            createTip(fundNode, fundNode, t2.tip, 1, 1);
        }
        if (t3.tip) {
            createTip(fundNode, assetNode, t3.tip, -1, 1, true);
        }

        createLink(investorNode, fundNode);
        createLink(fundNode, assetNode);
    };

    const calculateHoldingInvestor = (data) => {
        const { investorNode, fundNode, assetNode } = getNodes("holding");

        const f9 = getF9(data);
        investorNode.value = f9.value;
        investorNode.x -= 25;

        const t6NAV = getT6(data, true);
        const t6NotNAV = getT6(data, false);

        if (t6NAV.tip) {
            createTip(
                investorNode,
                investorNode,
                t6NAV.tip,
                -1,
                1,
                false,
                true
            );
        }

        if (t6NotNAV.tip) {
            createTip(investorNode, investorNode, t6NotNAV.tip, 1, 1);
        }

        createLink(investorNode, fundNode);
        createLink(fundNode, assetNode);
    };

    const calculateHoldingFund = (data) => {
        const { fundNode, assetNode } = getNodes("holding");
        const f9 = getF9(data);

        fundNode.value = f9.value;

        const t5 = getT5(data);

        if (t5.tip) {
            createTip(fundNode, fundNode, t5.tip, 1, 1);
        }
    };

    const calculateHoldingAsset = (data) => {
        const { fundNode, assetNode } = getNodes("holding");
        const f10 = getV4F10(data);
        assetNode.value = f10.value;
        assetNode.x += 25;

        const t4 = getT4(data);

        if (t4.tip) {
            createTip(fundNode, fundNode, t4.tip, -1, 1, false, true);
        }
    };

    const calculateDistributionFund = (data) => {
        const { fundNode, assetNode } = getNodes("distribution");

        assetNode.x -= 25;

        const f12 = getF12(data);
        fundNode.value = f12.value;

        const t7 = getT7(data);

        if (t7.tip) {
            createTip(assetNode, assetNode, t7.tip, 1, -1);
        }

        // createTips(
        //     filteredData,
        //     fixedValue,
        //     assetNode,
        //     (tipData) => {
        //         createTip(assetNode, assetNode, tipData, 1, -1);
        //     },
        //     (tipData) => {
        //         createTip(assetNode, assetNode, tipData, -1, -1, false, true);
        //     }
        // );
    };

    const calculateDistributionInvestor = (data) => {
        const { investorNode, fundNode, assetNode } = getNodes("distribution");

        const f11 = getV6F11(data);
        investorNode.value = f11.value;
        investorNode.x += 25;

        const t9 = getT9(data);
        const t8 = getT8(data);

        if (t9.tip) {
            createTip(fundNode, investorNode, t9.tip, 1, -1, true);
        }

        if (t8.tip) {
            createTip(fundNode, investorNode, t8.tip, -1, -1, true);
        }

        createLink(investorNode, fundNode);
        createLink(fundNode, assetNode);
    };

    const calculateSellingAsset = (data) => {
        const { assetNode } = getNodes("selling");
        const f16 = getV7F16(data);
        assetNode.value = f16.value;
    };

    const calculateSellingFund = (data) => {
        const { fundNode } = getNodes("selling");

        const f15 = getF15(data);
        fundNode.value = f15.value;

        const t10 = getT10(data);

        if (t10.tip) {
            createTip(fundNode, fundNode, t10.tip, -1, -1);
        }

        // createTips(
        //     filteredData,
        //     fundNode.value,
        //     fundNode,
        //     (tipData) => {
        //         createTip(fundNode, fundNode, tipData, 1, -1);
        //     },
        //     (tipData) => {
        //         createTip(fundNode, fundNode, tipData, -1, -1);
        //     }
        // );
    };

    const calculateSellingInvestor = (data) => {
        const { investorNode, fundNode, assetNode } = getNodes("selling");

        const f14 = getV8F14(data);
        investorNode.value = f14.value;

        const t11 = getT11(data);
        const t12NAV = getT12(data, true);
        const t12NotNAV = getT12(data, false);

        if (t11.tip) {
            createTip(fundNode, investorNode, t11.tip, 1, -1, true);
        }

        if (t12NAV.tip) {
            createTip(fundNode, investorNode, t12NAV.tip, 1, -1, true);
        }

        if (t12NotNAV.tip) {
            createTip(fundNode, investorNode, t12NotNAV.tip, 1, -1, true);
        }

        // createTips(
        //     filteredData,
        //     fundNode.value,
        //     investorNode,
        //     (tipData) => {
        //         createTip(fundNode, investorNode, tipData, 1, -1, true);
        //     },
        //     (tipData) => {
        //         createTip(fundNode, investorNode, tipData, -1, -1, true);
        //     }
        // );

        createLink(fundNode, investorNode);
        createLink(assetNode, fundNode);
    };

    const createXAxis = () => {
        for (const node of nodes) {
            if (node.level === "investor" && node.category === "investment") {
                const ax = {
                    x: node.x + node.width / 2,
                    y: node.y - 65,
                    value: "INVESTMENT",
                    top: true,
                };
                xAxis.push(ax);
            }
            if (node.level === "asset" && node.category === "holding") {
                const ax = {
                    x: node.x + node.width / 2,
                    y: node.y + 50,
                    value: "HOLDING",
                    top: false,
                };
                xAxis.push(ax);
            }
            if (node.level === "asset" && node.category === "distribution") {
                const ax = {
                    x: node.x + node.width / 2,
                    y: node.y + 50,
                    value: "DISTRIBUTION",
                    top: false,
                };
                xAxis.push(ax);
            }
            if (node.level === "asset" && node.category === "selling") {
                const ax = {
                    x: node.x + node.width / 2,
                    y: node.y + 50,
                    value: "SELLING",
                    top: false,
                };
                xAxis.push(ax);
            }
        }
    };

    const createYAxis = () => {
        for (const node of nodes) {
            if (node.level === "investor" && node.category === "investment") {
                const ay = {
                    x: node.x - node.width / 2,
                    y: node.y + node.height,
                    value: "INVESTOR",
                };
                yAxis.push(ay);
            }
            if (node.level === "fund" && node.category === "investment") {
                const ay = {
                    x: node.x - node.width / 2,
                    y: node.y + node.height,
                    value: "FUND",
                };
                yAxis.push(ay);
            }
            if (node.level === "asset" && node.category === "investment") {
                const ay = {
                    x: node.x - node.width / 2,
                    y: node.y + node.height,
                    value: "ASSET",
                };
                yAxis.push(ay);
            }
        }
    };

    const setTipsLegendId = (tips) => {
        const filteredFullComments = tips.filter(
            (t) => t.fullComments !== null
        );

        const uniqueFullComments = Array.from(
            new Set(filteredFullComments.map((i) => i.fullComments))
        ).map((i) => {
            return filteredFullComments.find((c) => c.fullComments === i);
        });

        for (const [i, uc] of uniqueFullComments.entries()) {
            uc.legendId = i === 0 ? "*" : i.toString();
        }

        for (const uc of filteredFullComments) {
            if (!uc.legendId) {
                const sameComment = uniqueFullComments.find(
                    (t) => t.fullComments === uc.fullComments
                );
                uc.legendId = sameComment.legendId;
            }
        }
    };

    generateDefaultData();
    calculateInvestmentFund(data);
    calculateInvestmentAsset(data);
    calculateHoldingAsset(data);
    calculateHoldingFund(data);
    calculateHoldingInvestor(data);
    calculateDistributionFund(data);
    calculateDistributionInvestor(data);
    calculateSellingAsset(data);
    calculateSellingFund(data);
    calculateSellingInvestor(data);
    createXAxis();
    createYAxis();
    setTipsLegendId(tips);

    createLines();

    return {
        width,
        height,
        margin,
        nodes,
        tips,
        links,
        lines,
        bottomLines,
        xAxis,
        yAxis,
    };
};

export default SankeyCustom;
