// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footer-styles_footer__1MP5V {\n    background-size: cover;\n}\n\n.footer-styles_container__2RUTN {\n    width: var(--container-width);\n    max-width: 100%;\n    padding: 33px var(--container-padding);\n    margin: auto;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.footer-styles_title__3-5nX {\n    font-weight: 700;\n}\n\n.footer-styles_container__2RUTN p {\n    margin-bottom: 26px;\n    line-height: 24px;\n    padding-left: 16px;\n}\n\n@media (max-width: 1680px) {\n    .footer-styles_container__2RUTN {\n        padding: 25px 80px;\n    }\n\n    .footer-styles_container__2RUTN p {\n        margin-bottom: 6px;\n    }\n\n    .footer-styles_container__2RUTN img {\n        height: 35px;\n    }\n}\n", "",{"version":3,"sources":["webpack://src/layouts/components/Footer/footer-styles.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;IACI,6BAA6B;IAC7B,eAAe;IACf,sCAAsC;IACtC,YAAY;IACZ,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI;QACI,kBAAkB;IACtB;;IAEA;QACI,kBAAkB;IACtB;;IAEA;QACI,YAAY;IAChB;AACJ","sourcesContent":[".footer {\n    background-size: cover;\n}\n\n.container {\n    width: var(--container-width);\n    max-width: 100%;\n    padding: 33px var(--container-padding);\n    margin: auto;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.title {\n    font-weight: 700;\n}\n\n.container p {\n    margin-bottom: 26px;\n    line-height: 24px;\n    padding-left: 16px;\n}\n\n@media (max-width: 1680px) {\n    .container {\n        padding: 25px 80px;\n    }\n\n    .container p {\n        margin-bottom: 6px;\n    }\n\n    .container img {\n        height: 35px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "footer-styles_footer__1MP5V",
	"container": "footer-styles_container__2RUTN",
	"title": "footer-styles_title__3-5nX"
};
export default ___CSS_LOADER_EXPORT___;
