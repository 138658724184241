import styles from "./button-styles.module.css";

const CloseButton = ({ onClick }) => {
    return (
        <button type={"button"} className={styles.close} onClick={onClick}>
            <img src={"/images/close.svg"} alt={""} />
        </button>
    );
};

export default CloseButton;
