import styles from "./header-styles.module.css";
import { Link, NavLink } from "react-router-dom";
import Aside from "../Aside/Aside";
import { useState, useEffect, useContext } from "react";

import { useLocation } from "react-router-dom";
import { DisclaimerContext } from "../../../context";

const Header = () => {
    let location = useLocation();

    const { disclaimer } = useContext(DisclaimerContext);

    const [active, setActive] = useState(false);
    const [page, setPage] = useState("");

    useEffect(() => {
        setActive(false);

        if (
            location.pathname === "/legal-structures" ||
            location.pathname === "/comparison-table"
        ) {
            setPage("?page=legal-structures");
        } else if (
            location.pathname === "/deal-taxes" ||
            location.pathname === "/tax-review"
        ) {
            setPage("?page=deal-taxes");
        } else {
            setPage("");
        }
    }, [location]);

    const handleMenuState = () => {
        setActive(!active);
    };

    return (
        <>
            <header className={styles.header} id={"header"}>
                <div className={styles.wrapper}>
                    <div className={styles.titleWrapper}>
                        <div className={styles.title}>
                            <a
                                href={`${process.env.REACT_APP_LANDING_URL}${page}`}
                            >
                                <img src={"/logo.svg"} alt="arrow-icon" />
                            </a>
                        </div>
                    </div>

                    <div className={styles.nav}>
                        <NavLink
                            className={`${styles.navItem} ${
                                !disclaimer ? styles.inactive : ""
                            }`}
                            to={"/deal-taxes"}
                            activeClassName={styles.active}
                        >
                            Deal taxes
                        </NavLink>
                        <NavLink
                            className={`${styles.navItem} ${
                                !disclaimer ? styles.inactive : ""
                            }  ${
                                location.pathname === "/comparison-table"
                                    ? styles.activeOpt
                                    : ""
                            }`}
                            activeClassName={styles.active}
                            to={"/legal-structures"}
                        >
                            Legal structures
                        </NavLink>
                        <a
                            className={styles.navItem}
                            href={`${process.env.REACT_APP_LANDING_URL}?page=contact-us`}
                        >
                            Contact us
                        </a>
                    </div>
                    <div className={styles.login}>
                        {disclaimer && (
                            <a
                                href={`${process.env.REACT_APP_LANDING_URL}?page=contact-us`}
                                className={styles.contactButton}
                            >
                                Contact us to get the full access
                            </a>
                        )}
                        <a
                            href={process.env.REACT_APP_CLIENT_URL + "login"}
                            className={styles.icon}
                        >
                            <svg
                                id="Icon"
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                            >
                                <rect
                                    id="Area"
                                    width="20"
                                    height="20"
                                    fill="#fcfcfc"
                                    opacity="0"
                                />
                                <g
                                    id="Icon-2"
                                    data-name="Icon"
                                    transform="translate(3.333 2.5)"
                                >
                                    <path
                                        id="Path"
                                        d="M16.667,17.5V15.833A3.334,3.334,0,0,0,13.333,12.5H6.667a3.334,3.334,0,0,0-3.334,3.333V17.5"
                                        transform="translate(-3.333 -2.5)"
                                        fill="none"
                                        stroke="#000"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                    />
                                    <circle
                                        id="Path-2"
                                        data-name="Path"
                                        cx="3.333"
                                        cy="3.333"
                                        r="3.333"
                                        transform="translate(3.334)"
                                        fill="none"
                                        stroke="#000"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                    />
                                </g>
                            </svg>

                            <svg
                                className={styles.iconActive}
                                id="Icon"
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                            >
                                <rect
                                    id="Area"
                                    width="20"
                                    height="20"
                                    fill="#064591"
                                    opacity="0"
                                />
                                <g
                                    id="Icon-2"
                                    data-name="Icon"
                                    transform="translate(1.29 2.499)"
                                >
                                    <path
                                        id="Path"
                                        d="M16.667,17.5V15.833A3.334,3.334,0,0,0,13.333,12.5H6.667a3.334,3.334,0,0,0-3.334,3.333V17.5Z"
                                        transform="translate(-1.29 -2.499)"
                                        fill="#1b53b1"
                                        stroke="#0c3b8d"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                    />
                                    <circle
                                        id="Path-2"
                                        data-name="Path"
                                        cx="3.333"
                                        cy="3.333"
                                        r="3.333"
                                        transform="translate(5.377 0.001)"
                                        fill="#1b53b1"
                                        stroke="#0c3b8d"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                    />
                                </g>
                            </svg>
                        </a>
                    </div>
                </div>
            </header>

            <Aside active={active} onStateChange={handleMenuState} />
        </>
    );
};

export default Header;
