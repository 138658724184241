import { ProgressBar } from "./index";

const ProgressFormat = (d, options) => {
    const currencyFormat = options ? options.currencyFormat : null;
    const progressValue = options ? options.progressVal : d;

    const progress = ProgressBar(progressValue ?? d);

    if (currencyFormat && d) {
        const arr = d.split(" ");

        if (!isNaN(parseInt(arr[0]))) {
            d =
                new Intl.NumberFormat("en-US")
                    .format(arr[0])
                    .replace(/,/g, "'") +
                " " +
                arr[1];
        }
    }

    return (
        <>
            {progress}
            {d}
        </>
    );
};
export default ProgressFormat;
