import { configureStore } from "@reduxjs/toolkit";
import tableReducer from "./pages/ComparisonTable/tableSlice";
import footerReducer from "./reducers/footerSlice";

export const store = configureStore({
    reducer: {
        table: tableReducer,
        footer: footerReducer,
    },
});
