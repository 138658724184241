export const calculateTaxExpanded = (data) => {
    const v1f5 = getV1F5();
    const t1 = getT1(data);
    const t2 = getT2(data);
    const t3 = getT3(data);
    const v2f7 = getV2F7(data);
    const v3 = getV3(data);
    const v4f10 = getV4F10(data);
    const t4NAV = getT4(data, true);
    const t5NAV = getT5(data, true);
    const t6NAV = getT6(data, true);
    const t6NotNAV = getT6(data, false);
    const v5f13 = getV5F13(data);
    const t7 = getT7(data);
    const t8 = getT8(data);
    const t9 = getT9(data);
    const v6f11 = getV6F11(data);
    const v7f16 = getV7F16(data);
    const t10NAV = getT10(data, true);
    const t11NAV = getT11(data, true);
    const t12NAV = getT12(data, true);
    const t12NotNAV = getT12(data, false);
    const v8f14 = getV8F14(data);

    const tSum =
        t1.value +
        t2.value +
        t3.value +
        t4NAV.value +
        t5NAV.value +
        t6NAV.value +
        t6NotNAV.value +
        t7.value +
        t8.value +
        t9.value +
        t10NAV.value +
        t11NAV.value +
        t12NAV.value +
        t12NotNAV.value;

    const totalTaxes = {
        value: parseValue(tSum),
        amount: `$${parseValue(tSum)}`,
        exemptions: "Total taxes",
    };

    const totalNetProceeds = {
        value: parseValue(
            v6f11.value + v8f14.value - t6NAV.value - t6NotNAV.value
        ),
        amount: `$${parseValue(
            v6f11.value + v8f14.value - t6NAV.value - t6NotNAV.value
        )}`,
        exemptions: "Total net proceeds",
    };

    const returnOnInvestments = {
        value: parseValue((totalNetProceeds.value / 100 - 1) * 100),
        amount: `${parseValue((totalNetProceeds.value / 100 - 1) * 100)}%`,
        exemptions: "Return on Investments",
        highlightRed: true,
    };

    return [
        v1f5,
        t1,
        t2,
        t3,
        v2f7,
        v3,
        v4f10,
        t4NAV,
        t5NAV,
        t6NAV,
        t6NotNAV,
        v5f13,
        t7,
        t8,
        t9,
        v6f11,
        v7f16,
        t10NAV,
        t11NAV,
        t12NAV,
        t12NotNAV,
        v8f14,
        totalTaxes,
        totalNetProceeds,
        returnOnInvestments,
    ];
};

const calculateTotalNetProceeds = (data) => {
    const v6f11 = getV6F11(data);
    const v8f14 = getV8F14(data);
    const t6NAV = getT6(data, true);
    const t6NotNAV = getT6(data, false);

    return {
        value: parseValue(
            v6f11.value + v8f14.value - t6NAV.value - t6NotNAV.value
        ),
        amount: `$${parseValue(
            v6f11.value + v8f14.value - t6NAV.value - t6NotNAV.value
        )}`,
        exemptions: "Total net proceeds",
    };
};

export const calculateReturnOnInvestment = (data) => {
    const totalNetProceeds = calculateTotalNetProceeds(data);

    return {
        value: parseValue((totalNetProceeds.value / 100 - 1) * 100),
        amount: `${parseValue((totalNetProceeds.value / 100 - 1) * 100)}%`,
        exemptions: "Return on Investments",
        highlightRed: true,
    };
};

export const getV1F5 = () => {
    return {
        value: 100,
        exemptions: "Initial Investment",
        amount: `$${100.0}`,
        highlight: true,
    };
};

const getF6 = (data) => {
    const v1f5 = getV1F5();
    const t1 = getT1(data);

    return {
        value: parseValue(v1f5.value - t1.value),
    };
};

const getA1 = (data) => {
    const f6 = getF6(data);
    const t2 = getT2(data);

    return {
        value: parseValue(f6.value - t2.value),
    };
};

export const getV2F7 = (data) => {
    const a1 = getA1(data);
    const t3 = getT3(data);

    return {
        value: parseValue(a1.value - t3.value),
        amount: `$${parseValue(a1.value - t3.value)}`,
        exemptions: "Net asset",
    };
};

export const getV3 = (data) => {
    const v2f7 = getV2F7(data);

    return {
        value: parseValue((v2f7.value / 100) * 20),
        amount: `$${parseValue((v2f7.value / 100) * 20)}`,
        exemptions: "Return to asset: 20%",
        highlight: true,
    };
};

export const getV4F10 = (data) => {
    const v2f7 = getV2F7(data);

    return {
        value: parseValue(1.2 * v2f7.value),
        amount: `$${parseValue(1.2 * v2f7.value)}`,
        exemptions: "Asset",
    };
};

const getA2 = (data) => {
    const v4f10 = getV4F10(data);
    const t4NAV = getT4(data, true);

    return {
        value: parseValue(v4f10.value + t4NAV.value),
    };
};

export const getF9 = (data) => {
    const a2 = getA2(data);
    const t5NAV = getT5(data, true);

    return {
        value: parseValue(a2.value + t5NAV.value),
    };
};

export const getV5F13 = () => {
    return {
        value: 10,
        amount: `$${10}`,
        exemptions: "Asset distribution",
        highlight: true,
    };
};

export const getF12 = (data) => {
    const v5f13 = getV5F13(data);
    const t7 = getT7(data);

    return {
        value: parseValue(v5f13.value - t7.value),
    };
};

const getA3 = (data) => {
    const f12 = getF12(data);
    const t8 = getT8(data);

    return {
        value: parseValue(f12.value - t8.value),
    };
};

export const getV6F11 = (data) => {
    const a3 = getA3(data);
    const t9 = getT9(data);

    return {
        value: parseValue(a3.value - t9.value),
        amount: `$${parseValue(a3.value - t9.value)}`,
        exemptions: "Net distribution",
    };
};

export const getV7F16 = (data) => {
    const f10 = getV4F10(data);
    const f13 = getV5F13(data);

    return {
        value: parseValue(f10.value - f13.value),
        amount: `$${parseValue(f10.value - f13.value)}`,
        exemptions: "Selling remaining asset",
        highlight: true,
    };
};

export const getF15 = (data) => {
    const t10NAV = getT10(data, true);
    const f16 = getV7F16(data);

    return {
        value: parseValue(f16.value - t10NAV.value),
    };
};

const getA4 = (data) => {
    const f15 = getF15(data);
    const t11NAV = getT11(data, true);

    return {
        value: parseValue(f15.value - t11NAV.value),
    };
};

export const getV8F14 = (data) => {
    const a4 = getA4(data);
    const t12NAV = getT12(data, true);
    const t12NotNAV = getT12(data, false);

    return {
        value: parseValue(a4.value - t12NAV.value - t12NotNAV.value),
        amount: `$${parseValue(a4.value - t12NAV.value - t12NotNAV.value)}`,
        exemptions: "Net sold",
    };
};

const checkFormula = (formula, taxRate) => {
    if (parseFloat(taxRate) > 0) {
        return formula;
    } else {
        return "";
    }
};

export const getT1 = (data) => {
    const investmentInvestor = getByLevelAndCategory(
        data,
        "investment",
        "investor"
    );
    if (!investmentInvestor) {
        return {
            value: 0,
        };
    }

    const v1 = getV1F5();
    let value = investmentInvestor.taxRate;
    value = parseValue(value);

    return {
        stage: "Investment",
        tax: `${investmentInvestor.level} pays ${investmentInvestor.tax}`,
        rate: `${investmentInvestor.taxRate}% of ${investmentInvestor.taxBase}`,
        comments: investmentInvestor.fullComments,
        taxesFormula: checkFormula(
            `${value}% * $${v1.value} = $${value}`,
            investmentInvestor.taxRate
        ),
        amount: `- $${value}`,
        value: value,
        tip: {
            tax: investmentInvestor.tax,
            percent: investmentInvestor.taxRate,
            value: value,
            fullComments: investmentInvestor.fullComments,
            taxBase: investmentInvestor.taxBase,
        },
    };
};

export const getT2 = (data) => {
    const investmentFund = getByLevelAndCategory(data, "investment", "fund");
    if (!investmentFund) {
        return {
            value: 0,
        };
    }

    const f6 = getF6(data);
    let value = investmentFund.taxRate * (f6.value / 100);
    value = parseValue(value);

    return {
        stage: "Investment",
        tax: `${investmentFund.level} pays ${investmentFund.tax}`,
        rate: `${investmentFund.taxRate}% of ${investmentFund.taxBase}`,
        comments: investmentFund.fullComments,
        taxesFormula: checkFormula(
            `${value}% * $${f6.value} = $${value}`,
            investmentFund.taxRate
        ),
        amount: `- $${value}`,
        value: value,
        tip: {
            tax: investmentFund.tax,
            percent: investmentFund.taxRate,
            value: value,
            fullComments: investmentFund.fullComments,
            taxBase: investmentFund.taxBase,
        },
    };
};

export const getT3 = (data) => {
    const investmentAsset = getByLevelAndCategory(data, "investment", "asset");
    if (!investmentAsset) {
        return {
            value: 0,
        };
    }

    const a1 = getA1(data);
    let value = investmentAsset.taxRate * (a1.value / 100);
    value = parseValue(value);

    return {
        stage: "Investment",
        tax: `${investmentAsset.level} pays ${investmentAsset.tax}`,
        rate: `${investmentAsset.taxRate}% of ${investmentAsset.taxBase}`,
        comments: investmentAsset.fullComments,
        taxesFormula: checkFormula(
            `${value}% * $${a1.value} = $${value}`,
            investmentAsset.taxRate
        ),
        amount: `- $${value}`,
        value: value,
        tip: {
            tax: investmentAsset.tax,
            percent: investmentAsset.taxRate,
            value: value,
            fullComments: investmentAsset.fullComments,
            taxBase: investmentAsset.taxBase,
        },
    };
};

export const getT4 = (data) => {
    const holdingAsset = getByLevelAndCategory(data, "holding", "asset", true);
    if (!holdingAsset) {
        return {
            value: 0,
        };
    }

    const f10 = getV4F10(data);
    let value =
        (holdingAsset.taxRate * (f10.value / 100)) / (1 - holdingAsset.taxRate);
    value = parseValue(value);

    return {
        stage: "Holding",
        tax: `${holdingAsset.level} pays ${holdingAsset.tax}`,
        rate: `${holdingAsset.taxRate}% of ${holdingAsset.taxBase}`,
        comments: holdingAsset.fullComments,
        taxesFormula: checkFormula(
            `${holdingAsset.taxRate}% * ${f10.value}% / (1 - ${holdingAsset.taxRate}%) = $${value}`,
            holdingAsset.taxRate
        ),
        amount: `- $${value}`,
        value: value,
        tip: {
            tax: holdingAsset.tax,
            percent: holdingAsset.taxRate,
            value: value,
            fullComments: holdingAsset.fullComments,
            taxBase: holdingAsset.taxBase,
        },
    };
};

export const getT5 = (data) => {
    const holdingFund = getByLevelAndCategory(data, "holding", "fund", true);
    if (!holdingFund) {
        return {
            value: 0,
        };
    }

    const a2 = getA2(data);
    let value =
        (holdingFund.taxRate * (a2.value / 100)) / (1 - holdingFund.taxRate);

    value = parseValue(value);

    return {
        stage: "Holding",
        tax: `${holdingFund.level} pays ${holdingFund.tax}`,
        rate: `${holdingFund.taxRate}% of ${holdingFund.taxBase}`,
        comments: holdingFund.fullComments,
        taxesFormula: checkFormula(
            `${holdingFund.taxRate}% * ${a2.value}% / (1 - ${holdingFund.taxRate}%) = $${value}`,
            holdingFund.taxRate
        ),
        amount: `- $${value}`,
        value: value,
        tip: {
            tax: holdingFund.tax,
            percent: holdingFund.taxRate,
            value: value,
            fullComments: holdingFund.fullComments,
            taxBase: holdingFund.taxBase,
        },
    };
};

export const getT6 = (data, nav) => {
    let holdingInvestor;
    let taxBase;

    if (nav) {
        const f9 = getF9(data);
        taxBase = f9.value;
        holdingInvestor = getByLevelAndCategory(
            data,
            "holding",
            "investor",
            true
        );
    } else {
        const f9 = getF9(data);
        const f6 = getF6(data);
        taxBase = parseValue(f9.value - f6.value);

        holdingInvestor = getByLevelAndCategory(
            data,
            "holding",
            "investor",
            false
        );
    }

    if (!holdingInvestor) {
        return {
            value: 0,
        };
    }

    let value = (holdingInvestor.taxRate * taxBase) / 100;
    value = parseValue(value);

    return {
        stage: "Holding",
        tax: `${holdingInvestor.level} pays ${holdingInvestor.tax}`,
        rate: `${holdingInvestor.taxRate}% of ${holdingInvestor.taxBase}`,
        comments: holdingInvestor.fullComments,
        taxesFormula: checkFormula(
            `${holdingInvestor.taxRate}% * $${taxBase} = $${value}`,
            holdingInvestor.taxRate
        ),
        amount: `- $${value}`,
        value: value,
        tip: {
            tax: holdingInvestor.tax,
            percent: holdingInvestor.taxRate,
            value: value,
            fullComments: holdingInvestor.fullComments,
            taxBase: holdingInvestor.taxBase,
        },
    };
};

export const getT7 = (data) => {
    const distributionAsset = getByLevelAndCategory(
        data,
        "distribution",
        "asset"
    );
    if (!distributionAsset) {
        return {
            value: 0,
        };
    }

    const f13 = getV5F13(data);
    let value = distributionAsset.taxRate * (f13.value / 100);
    value = parseValue(value);

    return {
        stage: "Distribution",
        tax: `${distributionAsset.level} pays ${distributionAsset.tax}`,
        rate: `${distributionAsset.taxRate}% of ${distributionAsset.taxBase}`,
        comments: distributionAsset.fullComments,
        taxesFormula: checkFormula(
            `${distributionAsset.taxRate}% * $${f13.value} = $${value}`,
            distributionAsset.taxRate
        ),
        amount: `- $${value}`,
        value: value,
        tip: {
            tax: distributionAsset.tax,
            percent: distributionAsset.taxRate,
            value: value,
            fullComments: distributionAsset.fullComments,
            taxBase: distributionAsset.taxBase,
        },
    };
};

export const getT8 = (data) => {
    const distributionFund = getByLevelAndCategory(
        data,
        "distribution",
        "fund"
    );
    if (!distributionFund) {
        return {
            value: 0,
        };
    }

    const f12 = getF12(data);
    let value = distributionFund.taxRate * (f12.value / 100);
    value = parseValue(value);

    return {
        stage: "Distribution",
        tax: `${distributionFund.level} pays ${distributionFund.tax}`,
        rate: `${distributionFund.taxRate}% of ${distributionFund.taxBase}`,
        comments: distributionFund.fullComments,
        taxesFormula: checkFormula(
            `${distributionFund.taxRate}% * $${f12.value} = $${value}`,
            distributionFund.taxRate
        ),
        amount: `- $${value}`,
        value: value,
        tip: {
            tax: distributionFund.tax,
            percent: distributionFund.taxRate,
            value: value,
            fullComments: distributionFund.fullComments,
            taxBase: distributionFund.taxBase,
        },
    };
};

export const getT9 = (data) => {
    const distributionInvestor = getByLevelAndCategory(
        data,
        "distribution",
        "investor"
    );
    if (!distributionInvestor) {
        return {
            value: 0,
        };
    }

    const a3 = getA3(data);
    let value = distributionInvestor.taxRate * (a3.value / 100);
    value = parseValue(value);

    return {
        stage: "Distribution",
        tax: `${distributionInvestor.level} pays ${distributionInvestor.tax}`,
        rate: `${distributionInvestor.taxRate}% of ${distributionInvestor.taxBase}`,
        comments: distributionInvestor.fullComments,
        taxesFormula: checkFormula(
            `${distributionInvestor.taxRate}% * $${a3.value} = $${value}`,
            distributionInvestor.taxRate
        ),
        amount: `- $${value}`,
        value: value,
        tip: {
            tax: distributionInvestor.tax,
            percent: distributionInvestor.taxRate,
            value: value,
            fullComments: distributionInvestor.fullComments,
            taxBase: distributionInvestor.taxBase,
        },
    };
};

export const getT10 = (data) => {
    const sellingAsset = getByLevelAndCategory(data, "selling", "asset", true);
    if (!sellingAsset) {
        return {
            value: 0,
        };
    }

    const f16 = getV7F16(data);
    let value = sellingAsset.taxRate * (f16.value / 100);
    value = parseValue(value);

    return {
        stage: "Selling",
        tax: `${sellingAsset.level} pays ${sellingAsset.tax}`,
        rate: `${sellingAsset.taxRate}% of ${sellingAsset.taxBase}`,
        comments: sellingAsset.fullComments,
        taxesFormula: checkFormula(
            `${value}% * $${f16.value} = $${value}`,
            sellingAsset.taxRate
        ),
        amount: `- $${value}`,
        value: value,
        tip: {
            tax: sellingAsset.tax,
            percent: sellingAsset.taxRate,
            value: value,
            fullComments: sellingAsset.fullComments,
            taxBase: sellingAsset.taxBase,
        },
    };
};

export const getT11 = (data) => {
    const sellingFund = getByLevelAndCategory(data, "selling", "fund", true);
    if (!sellingFund) {
        return {
            value: 0,
        };
    }

    const f15 = getF15(data);
    let value = sellingFund.taxRate * (f15.value / 100);
    value = parseValue(value);

    return {
        stage: "Selling",
        tax: `${sellingFund.level} pays ${sellingFund.tax}`,
        rate: `${sellingFund.taxRate}% of ${sellingFund.taxBase}`,
        comments: sellingFund.fullComments,
        taxesFormula: checkFormula(
            `${value}% * $${f15.value} = $${value}`,
            sellingFund.taxRate
        ),
        amount: `- $${value}`,
        value: value,
        tip: {
            tax: sellingFund.tax,
            percent: sellingFund.taxRate,
            value: value,
            fullComments: sellingFund.fullComments,
            taxBase: sellingFund.taxBase,
        },
    };
};

export const getT12 = (data, nav) => {
    let taxesFormula, value;

    const sellingInvestor = getByLevelAndCategory(
        data,
        "selling",
        "investor",
        nav
    );

    if (!sellingInvestor) {
        return {
            value: 0,
        };
    }

    if (nav) {
        const a4 = getA4(data);
        value = sellingInvestor.taxRate * (a4.value / 100);
        value = parseValue(value);

        taxesFormula = `${sellingInvestor.taxRate}% * $${a4.value} = $${value}`;
    } else {
        const f9 = getF9(data);
        const f6 = getF6(data);
        const a3 = getA3(data);
        const taxBase = parseValue(f9.value - f6.value - a3.value);
        value = sellingInvestor.taxRate * (taxBase / 100);
        value = parseValue(value);

        taxesFormula = checkFormula(
            `${sellingInvestor.taxRate}% * ($${f9.value} - $${f6.value} - $${a3.value}) = $${value}`,
            sellingInvestor.taxRate
        );
    }

    return {
        stage: "Selling",
        tax: `${sellingInvestor.level} pays ${sellingInvestor.tax}`,
        rate: `${sellingInvestor.taxRate}% of ${sellingInvestor.taxBase}`,
        comments: sellingInvestor.fullComments,
        taxesFormula,
        amount: `- $${value}`,
        value: value,
        tip: {
            tax: sellingInvestor.tax,
            percent: sellingInvestor.taxRate,
            value: value,
            fullComments: sellingInvestor.fullComments,
            taxBase: sellingInvestor.taxBase,
        },
    };
};

const getByLevelAndCategory = (data, category, level, nav) => {
    if (nav) {
        const result = data.find(
            (d) =>
                d.eventCategory === category &&
                d.level === level &&
                d.taxBase === "NAV"
        );
        return result;
    } else if (nav === false) {
        const result = data.find(
            (d) =>
                d.eventCategory === category &&
                d.level === level &&
                d.taxBase !== "NAV"
        );

        return result;
    }
    const result = data.find(
        (d) => d.eventCategory === category && d.level === level
    );

    return result;
};

const parseValue = (num) => {
    return parseFloat(num.toFixed(2));
};
