import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { DisclaimerContext } from "../context";

const PrivateRouteWithLayout = (props) => {
    const { layout: Layout, title, component: Component, ...rest } = props;

    const { disclaimer, isLoading } = useContext(DisclaimerContext);

    if (isLoading) {
        return (
            <Route
                {...rest}
                render={(matchProps) => <Layout title={title}></Layout>}
            />
        );
    } else {
        if (disclaimer) {
            return (
                <Route
                    {...rest}
                    render={(matchProps) => (
                        <Layout title={title}>
                            <Component {...matchProps} />
                        </Layout>
                    )}
                />
            );
        } else {
            return <Redirect to={"/disclaimer"} />;
        }
    }
};

PrivateRouteWithLayout.propTypes = {
    component: PropTypes.any.isRequired,
    layout: PropTypes.any.isRequired,
    path: PropTypes.string,
};

export default PrivateRouteWithLayout;
