import styles from "./main-structures-detail.module.css";
import { CloseButton } from "../Button";
import React from "react";

const MainStructuresDetail = ({ activeInvestment, onClose }) => {
    const legalForms = activeInvestment.ribbons.map((r) => r.to);

    const Field = ({ title, value, useCheckbox, footnote, info }) => {
        const trimmed = value ? value.toString().trim() : "";

        return (
            <div>
                <h6 className={styles.fieldHeader}>
                    {title}
                    {footnote ? (
                        <span className={styles.footnote}>{footnote}</span>
                    ) : (
                        ""
                    )}

                    {info && (
                        <span className={`${styles.tip} ${styles.tipSmaller}`}>
                            <span className={styles.tipBtn}>
                                <span className={styles.tipBtnImg}>
                                    <img alt={""} src={"/images/info.png"} />
                                    <img
                                        alt={""}
                                        src={"/images/info-active.png"}
                                    />
                                </span>
                            </span>

                            <p className={styles.tipBox}>{info}</p>
                        </span>
                    )}
                </h6>

                {useCheckbox ? (
                    <div
                        className={`${styles.check} ${
                            trimmed.toLowerCase() === "true"
                                ? styles.active
                                : ""
                        }`}
                    />
                ) : (
                    <span>{trimmed}</span>
                )}
            </div>
        );
    };

    return (
        <div className={styles.boxOuter}>
            <div className={styles.box}>
                <CloseButton onClick={() => onClose()} />
                <h6 className={styles.fundTitle}>Investment fund</h6>
                <h5 className={styles.fundTitleFN}>
                    {activeInvestment.data.fullName}
                </h5>
                <div className={styles.data}>
                    <Field
                        title={"DOMICILE"}
                        value={activeInvestment.data.domicileName}
                    />
                    <Field
                        title={"REGULATION"}
                        value={activeInvestment.data.regulationType}
                    />
                    <Field
                        title={"TAX STATUS"}
                        value={activeInvestment.data.applicableTax.replace(
                            /\w\S*/g,
                            (w) => w.replace(/^\w/, (c) => c.toUpperCase())
                        )}
                    />
                    <Field
                        title={"LEVEL OF REGULATION"}
                        value={activeInvestment.data.levelOfRegulation.replace(
                            /\w\S*/g,
                            (w) => w.replace(/^\w/, (c) => c.toUpperCase())
                        )}
                    />
                    <Field
                        title={"COMPLEXITY"}
                        value={activeInvestment.data.regulationComplexity}
                    />
                    <Field
                        title={"IF AIF"}
                        value={activeInvestment.data.ifAif
                            .toString()
                            .replace(/\w\S*/g, (w) =>
                                w.replace(/^\w/, (c) => c.toUpperCase())
                            )}
                        useCheckbox={true}
                        info={"Alternative Investment Fund"}
                    />
                    <Field
                        title={"MAX SHAREHOLDERS"}
                        value={activeInvestment.data.maxNumOfShareholders}
                    />
                    <Field
                        title={"COST TO LAUNCH"}
                        value={`$${activeInvestment.data.costsToLaunchUsdFormated}`}
                        footnote={1}
                    />
                    <Field
                        title={"ASSET MANAGER"}
                        value={activeInvestment.data.assetManagerToShow}
                    />
                    <Field
                        title={"ANNUAL COST FOR $100M"}
                        value={`$${activeInvestment.data.annualCostsFormated}`}
                        footnote={1}
                    />
                </div>

                <div className={styles.borderDataTop}>
                    <h6>Legal form</h6>
                    <h6>
                        Days to launch{" "}
                        <span className={styles.footnote}>2</span>
                    </h6>
                </div>
                {legalForms.map((lf, key) => (
                    <div key={key} className={styles.borderData}>
                        <h5>{lf.name}</h5>
                        <h5>
                            {
                                lf.launchBubbles.find(
                                    (lb) =>
                                        lb.investmentFund ===
                                        activeInvestment.name
                                ).cost
                            }
                        </h5>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MainStructuresDetail;
