import { getInvestmentFunds } from "../utils";
import { setActiveFund } from "../tableSlice";
import { useDispatch, useSelector } from "react-redux";
import styles from "./tr-styles.module.css";

const TableHeader = ({ sdKey, excludeFeat }) => {
    const data = useSelector((state) => state.table.data);
    const activeFund = useSelector((state) => state.table.activeFund);

    const dispatch = useDispatch();

    return (
        <thead>
            <tr>
                {!excludeFeat && <th className={"fund-th"}>Feature</th>}

                {getInvestmentFunds(data, sdKey).map((fund, key) => {
                    return (
                        <th
                            key={key}
                            onClick={() => dispatch(setActiveFund(fund))}
                            className={`fund-th ${
                                fund === activeFund ? styles.activeHeader : ""
                            }`}
                        >
                            {fund}
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
};

export default TableHeader;
