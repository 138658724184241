import ComparisonTableSection from "../ComparisonTableSection";
import TableHeader from "../components/TableHeader";
import { Tr } from "../components";

import { DescriptionFormat, OpenClosedFormat } from "../formats";

import { useSelector } from "react-redux";
import TrProvider from "../components/TrProvider";
import ComparisonTableSectionProvider from "../ComparisonTableSectionProvider";

const SD11 = ({
    triggerCollapse,
    setTablesCollapsed,
    tablesCollapsed,
    collapseOnClick,
}) => {
    const data = useSelector((state) => state.table.data);

    return data && data.sd10 ? (
        <ComparisonTableSectionProvider>
            <ComparisonTableSection
                triggerCollapse={triggerCollapse}
                setTablesCollapsed={setTablesCollapsed}
                tablesCollapsed={tablesCollapsed}
                collapseOnClick={collapseOnClick}
                title={"Tax features 1/2"}
            >
                <table>
                    <TableHeader sdKey={"sd11"} />

                    <tbody>
                        <Tr
                            featName={"Legal form"}
                            dataKey={"sd11"}
                            colKey={"legalForm"}
                        />

                        <Tr
                            featName={"Legal type"}
                            dataKey={"sd11"}
                            colKey={"legalType"}
                        />

                        <Tr
                            featName={"If open ended"}
                            dataKey={"sd11"}
                            colKey={"ifOpenEnded"}
                            formatFunc={OpenClosedFormat}
                        />

                        <Tr
                            featName={"Tax status for Swiss investors"}
                            dataKey={"sd11"}
                            colKey={"taxStatusForChInvestors"}
                        />

                        <Tr
                            featName={"Access to double taxation treaties"}
                            dataKey={"sd11"}
                            colKey={"dtt"}
                        />

                        <TrProvider>
                            <Tr
                                featName={
                                    "Countries eligible for double taxation area"
                                }
                                dataKey={"sd11"}
                                colKey={"dttCountries"}
                                formatFunc={DescriptionFormat}
                            />
                        </TrProvider>

                        <Tr
                            featName={"US check-the-box eligibility"}
                            dataKey={"sd11"}
                            colKey={"usCheckTheBoxEligibility"}
                        />
                    </tbody>
                </table>
            </ComparisonTableSection>
        </ComparisonTableSectionProvider>
    ) : null;
};

export default SD11;
