import { Link, useHistory } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import styles from "./deal-taxes-styles.module.css";

import axios from "../../utils/axios";

import { Checkbox } from "../../shared/Checkbox";
import { DealTaxesDetail } from "../../shared/DealTaxesDetail";
import { Sankey } from "../../shared/Diagram";
import SankeyCustom from "../../shared/Diagram/SankeyCustom";
import { SelectionPopup } from "../../shared/SelectionPopup";
import ResultTableLeft from "../../shared/ResultTable/ResultTableLeft";
import ResultTableRight from "../../shared/ResultTable/ResultTableRight";
import { ResultTable } from "../../shared/ResultTable";
import { calculateReturnOnInvestment as calculateROI } from "../DealTaxesExpanded/utils";

import React from "react";
import { DisclaimerContext } from "../../context";

const DealTaxes = () => {
    const [selectedData, setSelectedData] = useState({
        investor: "",
        asset: "",
        investmentFund: "",
        openEnded: false,
        corporate: false,
        partnership: false,
        booked: false,
    });

    const { disclaimer } = useContext(DisclaimerContext);

    const [investors, setInvestors] = useState([]);
    const [assets, setAssets] = useState([]);
    const [investmentFunds, setInvestmentFunds] = useState([]);

    const [assetClass, setAssetClass] = useState("");
    const [investor, setInvestor] = useState("");
    const [investmentFund, setInvestmentFund] = useState("");

    const [showPopup, setShowPopup] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [sankeyData, setSankeyData] = useState(null);

    const [activeCategory, setActiveCategory] = useState(null);

    const [checkClicked, setCheckClicked] = useState(false);
    const [toggleRequest, setToggleRequest] = useState(false);

    const [returnOnInvestment, setReturnOnInvestment] = useState("");
    const [tableUrl, setTableUrl] = useState("");
    const [legend, setLegend] = useState(null);
    const [legends, setLegends] = useState([]);

    const history = useHistory();

    useEffect(() => {
        return history.listen((location) => {
            const params = new URLSearchParams(location.search);

            const investor = params.get("investor");
            const asset = params.get("asset");
            const investmentFund = params.get("investmentFund");

            if (!investor || !asset || !investmentFund) {
                setShowPopup(true);
                return;
            }

            setSelectedData({
                ...selectedData,
                investor,
                asset,
                investmentFund,
            });
        });
    }, [history]);

    useEffect(() => {
        const params = new URLSearchParams(history.location.search);

        if (params.has("back")) {
            const investor = params.get("investorId");
            const asset = params.get("assetClass");
            const investmentFund = params.get("investmentFundId");

            if (!investor && !asset && !investmentFund) {
                setShowPopup(true);
                return;
            }

            const booked = params.get("booked");
            const corporate = params.get("corporate");
            const openEnded = params.get("openEnded");

            setAssetClass(params.get("assetClass"));
            setInvestor(params.get("investorName"));
            setInvestmentFund(params.get("investmentFundName"));

            setSelectedData({
                ...selectedData,
                investor,
                asset,
                investmentFund,
                booked: booked === "true" ? true : false,
                corporate: corporate === "true" ? true : false,
                openEnded: openEnded === "true" ? true : false,
            });

            setCheckClicked(true);
        } else {
            setShowPopup(true);
        }
    }, []);

    useEffect(() => {
        if (toggleRequest) {
            (async () => await getSankeyData(true))();
        }
    }, [toggleRequest]);

    useEffect(() => {
        if (
            checkClicked &&
            selectedData.asset &&
            selectedData.investor &&
            selectedData.investmentFund
        ) {
            (async () => await getSankeyData(false))();
        }
    }, [
        selectedData.openEnded,
        selectedData.booked,
        selectedData.corporate,
        checkClicked,
    ]);

    const handleCheckbox = (prop) => async (event) => {
        setCheckClicked(true);

        const checked = event.target.checked;
        const tmp = { ...selectedData, [prop]: checked };
        if (prop === "corporate") {
            tmp.partnership = !checked;
        }

        setSelectedData({ ...tmp });
    };

    const handleFormSubmission = async (formSelectedData, data) => {
        const tmp = {
            ...selectedData,
            openEnded: false,
            corporate: false,
            booked: false,
        };

        setAssetClass(
            data.assets.find((a) => a.assetId == formSelectedData.asset)
                ?.assetClass
        );
        setInvestor(
            data.investors.find(
                (i) => i.investorId == formSelectedData.investor
            )?.investor
        );

        setInvestmentFund(
            data.investmentFunds.find(
                (i) => i.investmentFundId == formSelectedData.investmentFund
            )?.investmentFund
        );

        setSelectedData({ ...tmp, ...formSelectedData });

        setAssets(data.assets);
        setInvestors(data.investors);
        setInvestmentFunds(data.investmentFunds);

        setShowPopup(false);

        setToggleRequest(true);
    };

    const handlePopupClose = async (formSelectedData) => {
        setSelectedData({ ...selectedData, ...formSelectedData });

        setShowPopup(false);
    };

    const getOpenEnded = async (assetClass) => {
        const data = await axios.get("/check-open-ended", {
            params: {
                assetClass: assetClass,
            },
        });

        return data.data;
    };

    const calculateReturnOnInvestment = (data) => {
        const result = calculateROI(data);

        setReturnOnInvestment(result.value.toFixed(1));
    };

    const getSankeyData = async (changeCorporate) => {
        if (!assetClass) return;

        setToggleRequest(false);
        setCheckClicked(false);

        let isCorporate = selectedData.corporate;
        let isOpenEnded = selectedData.openEnded;

        if (changeCorporate) {
            const openEnded = await getOpenEnded(assetClass);

            isCorporate = openEnded.legalType.trim() === "corporate";
            isOpenEnded = openEnded.ifRecommendedOpenEnded;

            setSelectedData({
                ...selectedData,
                corporate: isCorporate,
                partnership: false,
                openEnded: isOpenEnded,
            });
        }

        const data = await axios.get("/demo/sankey", {
            params: {
                openEnded: isOpenEnded,
                corporate: isCorporate,
                partnership: selectedData.partnership,
                booked: selectedData.booked,
            },
        });

        setTableUrl(
            `?&investorName=${investor}&investmentFundName=${investmentFund}&investorId=${selectedData.investor}&investmentFundId=${selectedData.investmentFund}&assetClass=${assetClass}&openEnded=${isOpenEnded}&corporate=${isCorporate}&partnership=${selectedData.partnership}&booked=${selectedData.booked}`
        );

        const sankey = SankeyCustom(data.data.result);

        calculateReturnOnInvestment(data.data.result);

        setSankeyData(sankey);
        setLegends(
            Array.from(new Set(sankey.tips.map((i) => i.legendId))).map((i) => {
                return sankey.tips.find((c) => c.legendId === i);
            })
        );
    };

    const onDataChange = (category) => {
        setActiveCategory(category);
        if (category) {
            setShowDetails(true);
        } else {
            setShowDetails(false);
        }
    };

    const handleDetailsClose = () => {
        setShowDetails(false);
        setActiveCategory(null);
    };

    const Info = () => {
        return (
            <div className={styles.info}>
                <div>
                    <h3>
                        This{" "}
                        <span className={styles.highlight}>tax review</span>{" "}
                        example is for:
                    </h3>

                    <h5>
                        <span>Initial investment: </span>
                        <span>$100</span>
                    </h5>
                    <h5>
                        <span>Return on asset: </span>
                        <span>20%</span>
                    </h5>
                    <h5>
                        <span>Distribution: </span>
                        <span>$10</span>
                    </h5>

                    <h5>
                        <span>Return on investment</span>

                        <span>{returnOnInvestment}%</span>
                    </h5>
                </div>

                <img src="/images/arrow-icon.svg" alt="" />
            </div>
        );
    };

    const onLegendChange = (legendId) => {
        setLegend(legendId);
    };

    const Tips = () => {
        return (
            <>
                <div className={`${styles.graphTip} ${styles.initial}`}>
                    Dollar amount at each level
                </div>
                <div className={`${styles.graphTip} ${styles.taxed}`}>
                    Taxes
                </div>
                <div className={`${styles.graphTip} ${styles.embedded}`}>
                    Taxes embedded in the structure
                </div>
                <div className={`${styles.graphTip} ${styles.notEmbedded}`}>
                    Taxes not embedded in the structure
                </div>
            </>
        );
    };

    const AdditionalChecks = ({ handleCheckbox }) => {
        return (
            <>
                <Checkbox
                    label={"open-ended"}
                    value={true}
                    checked={selectedData.openEnded}
                    onChange={handleCheckbox("openEnded")}
                    disabled={
                        ["1", "5", "6", "7", "8", "9"].indexOf(
                            selectedData.investmentFund
                        ) > -1
                    }
                    info={`Collective investment scheme whose investors have a legal right to redeem or withdraw their shares or interest at least once per year.`}
                />
                <Checkbox
                    name={"corporate"}
                    label={"corporate"}
                    value={true}
                    checked={selectedData.corporate}
                    onChange={handleCheckbox("corporate")}
                    disabled={["4"].indexOf(selectedData.investmentFund) > -1}
                    info={
                        <>
                            A corporation is an independent legal entity owned
                            by its shareholders (Lux: Sàrl, SA, SCA; Cayman:
                            company, LLC, SPC). <br />
                            Whereas a limited partnership is a partnership that
                            is established by contract between one or more
                            unlimited partners (or general partners) and one or
                            more limited partners (Lux: SCS, SCSp, SCA; Cayman:
                            LP).
                        </>
                    }
                />
                <Checkbox
                    label={"booked at NAV"}
                    value={true}
                    checked={selectedData.booked}
                    onChange={handleCheckbox("booked")}
                    disabled={
                        selectedData.investor == 7 || selectedData.investor == 8
                    }
                    info={`If the investment was booked at NAV or at cost.`}
                />
            </>
        );
    };

    return (
        <>
            {showPopup && (
                <SelectionPopup
                    useInvestmentStructure={true}
                    onClose={handlePopupClose}
                    onSubmit={handleFormSubmission}
                />
            )}

            <ResultTable>
                <ResultTableLeft
                    tableName={"Deal taxes"}
                    tableInfo={<Info />}
                    comments={
                        legend
                            ? legends.find((l) => l.legendId === legend)
                            : null
                    }
                    investor={investor}
                    investmentFund={investmentFund}
                    asset={assetClass}
                    onEdit={() => {
                        setShowPopup(true);
                    }}
                />

                <div className={styles.graph}>
                    <div className={styles.graphContainer}>
                        {sankeyData && sankeyData.links && (
                            <Sankey
                                sankey={sankeyData}
                                activeCategory={activeCategory}
                                onDataChange={onDataChange}
                                onLegendChange={onLegendChange}
                            />
                        )}
                    </div>
                    <Link
                        to={{
                            pathname: "/tax-review",
                            search: tableUrl,
                        }}
                        className={styles.btn}
                    >
                        Learn more
                    </Link>
                </div>

                <ResultTableRight
                    showDetails={showDetails}
                    tips={<Tips />}
                    details={
                        <DealTaxesDetail
                            activeCategory={activeCategory}
                            tips={sankeyData?.tips}
                            onClose={handleDetailsClose}
                            legendChange={onLegendChange}
                        />
                    }
                    checkboxes={
                        <AdditionalChecks handleCheckbox={handleCheckbox} />
                    }
                    exploreText={`Click on the visualization to explore the details`}
                />
            </ResultTable>
        </>
    );
};

export default DealTaxes;
