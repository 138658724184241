import { useEffect, useState } from "react";

const useCheckDisclaimer = () => {
    const [disclaimer, setDisclaimer] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        console.log(
            'sessionStorage.getItem("demo")',
            sessionStorage.getItem("demo")
        );
        if (sessionStorage.getItem("demo")) {
            setDisclaimer(true);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    }, []);

    return {
        disclaimer,
        setDisclaimer,
        isLoading,
        setIsLoading,
    };
};

export default useCheckDisclaimer;
