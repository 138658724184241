import { useEffect, useState } from "react";

import classes from "./sankey-styles.module.css";

const Sankey = ({ sankey, onDataChange, activeCategory, onLegendChange }) => {
    //const [activeCategory, setActiveCategory] = useState(null);
    const [prevActive, setPrevActive] = useState(null);

    useEffect(() => {
        if (!activeCategory && prevActive !== activeCategory) {
            setDefaultOpacity("investment");
            setDefaultOpacity("holding");
            setDefaultOpacity("selling");
            setDefaultOpacity("distribution");
        }

        setPrevActive(activeCategory);
    }, [activeCategory]);

    useEffect(() => {
        setDefaultOpacity("investment");
        setDefaultOpacity("holding");
        setDefaultOpacity("selling");
        setDefaultOpacity("distribution");

        if (activeCategory) {
            setActiveOpacity(activeCategory);
        }
    }, [sankey]);

    const setDefaultOpacity = (e) => {
        const els = document.querySelectorAll(`.${e}`);

        for (const el of els) {
            el.style.opacity = null;
        }
    };

    const onLinkEnter = (e) => {
        setActiveOpacity(e);
    };

    const setActiveOpacity = (e) => {
        const els = document.querySelectorAll(`.${e}`);

        for (const el of els) {
            if (el.classList.contains("tip-link")) {
                el.style.opacity = 0.5;
            } else if (
                el.classList.contains("tip-arrow") &&
                !el.classList.contains("link")
            ) {
                el.style.opacity = 1;
            } else {
                el.style.opacity = 0.8;
            }
        }
    };

    const onClick = (e) => {
        if (e.target.tagName === "path") {
            const category = e.target.className.baseVal;

            if (activeCategory === category) {
                setDefaultOpacity(activeCategory);
                onDataChange(null);

                return;
            }

            onLinkEnter(category);

            if (activeCategory) {
                setDefaultOpacity(activeCategory);
            }

            onDataChange(category);
        } else {
            setDefaultOpacity(activeCategory);
            onDataChange(null);
        }
    };

    const Node = ({ node }) => {
        const minWidth = 160;
        let width = node.width * 2 + 50;
        if (width < minWidth) width = minWidth;

        return (
            <g transform={`translate(${node.x}, ${node.y})`}>
                <rect
                    x={0}
                    y={0}
                    width={node.width}
                    height={node.height}
                    fill="#1040b5"
                />
                <text
                    x={node.width / 2}
                    y={14}
                    dx={node.width < 80 ? "2.5em" : "0"}
                    fontSize={15}
                    textAnchor="middle"
                    fontWeight={600}
                    fill={node.width < 50 ? "#1040b5" : "#ffffff"}
                >
                    {`$${node.value}`}
                </text>
            </g>
        );
    };

    const XAxis = ({ axis }) => {
        if (axis.top) {
            return (
                <g transform={`translate(${axis.x}, ${axis.y})`}>
                    <text
                        x={0}
                        y={0}
                        dy="2.6em"
                        fontSize={20}
                        fontWeight={100}
                        textAnchor="middle"
                        fill="#1040b5"
                    >
                        {axis.value}
                    </text>
                    <polygon
                        transform={`translate(-8, 0)`}
                        points="0,0 18,0 8,18"
                        style={{ fill: "#1040b5" }}
                    />
                </g>
            );
        } else {
            return (
                <g transform={`translate(${axis.x}, ${axis.y})`}>
                    <text
                        x={0}
                        y={0}
                        fontSize={20}
                        fontWeight={100}
                        textAnchor="middle"
                        fill="#0c3b8d"
                    >
                        {axis.value}
                    </text>
                    <polygon
                        transform={`translate(-8, 8)`}
                        points="8,0 18,18 0,18"
                        style={{ fill: "#0c3b8d" }}
                    />
                </g>
            );
        }
    };

    const YAxis = ({ axis }) => {
        return (
            <g transform={`translate(${axis.x}, ${axis.y})`}>
                <text
                    x={0}
                    y={0}
                    fontSize={16}
                    fontWeight={100}
                    textAnchor="middle"
                    fill="#000000"
                >
                    {axis.value}
                </text>
            </g>
        );
    };

    const handleTipEnter = (tip) => {
        onLegendChange(tip.legendId);
    };

    const handleMouseLeave = () => {
        onLegendChange(null);
    };

    const Tip = ({ tip }) => {
        return (
            <g
                transform={`translate(${tip.x}, ${tip.y})`}
                onMouseEnter={() => handleTipEnter(tip)}
                onMouseLeave={handleMouseLeave}
            >
                <rect
                    width={tip.width}
                    height={tip.height}
                    x={0}
                    y={0}
                    fill="#fff"
                    stroke="#ff5900"
                    strokeWidth={1}
                    rx={5}
                />
                <text
                    x={tip.width / 2}
                    y={20}
                    fontSize={15}
                    textAnchor="middle"
                    fill="#ff5900"
                >
                    {`${tip.percent}%`}
                </text>
                <g x={tip.width / 2} y={35}>
                    <text
                        x={tip.width / 2}
                        y={35}
                        fontSize={11}
                        textAnchor="middle"
                        fill="black"
                    >
                        {tip.tax}
                        {tip.fullComments && (
                            <tspan dx={1} dy={-4} fontSize={8}>
                                {tip.legendId}
                            </tspan>
                        )}
                    </text>
                </g>
                <text
                    x={tip.width / 2}
                    y={50}
                    fontSize={12}
                    textAnchor="middle"
                    fontWeight={700}
                    fill="black"
                >
                    {`$${tip.value.toFixed(2)}`}
                </text>
            </g>
        );
    };

    const Triangle = ({ link, i }) => {
        return (
            <>
                <line
                    className={link.class}
                    key={i}
                    x1={link.source[0]}
                    y1={link.target[1]}
                    x2={link.source[0]}
                    y2={link.target[1] + 40}
                    stroke={link.color}
                    strokeDasharray={"4"}
                    fill="none"
                    opacity={link.opacity}
                    strokeWidth={3}
                />

                <g
                    transform={`translate(${link.source[0] - 12}, ${
                        link.source[1] - 52
                    })`}
                >
                    <line
                        x1={0}
                        y1={0}
                        x2={24}
                        y2={0}
                        stroke={link.color}
                        strokeWidth={2}
                        opacity={link.opacity}
                    />
                    <line
                        x1={4}
                        y1={4}
                        x2={20}
                        y2={4}
                        stroke={link.color}
                        strokeWidth={2}
                        opacity={link.opacity}
                    />
                    <line
                        x1={8}
                        y1={8}
                        x2={16}
                        y2={8}
                        stroke={link.color}
                        strokeWidth={2}
                        opacity={link.opacity}
                    />
                    <line
                        x1={12}
                        y1={12}
                        x2={12}
                        y2={12}
                        stroke={link.color}
                        strokeWidth={2}
                        opacity={link.opacity}
                    />
                </g>
            </>
        );
    };

    const Line = ({ line }) => {
        return (
            <>
                <line
                    x1={line.x1}
                    y1={line.y}
                    x2={line.x2}
                    y2={line.y}
                    stroke="#000"
                    strokeWidth={2}
                    // style={{ transform: `translateX(-${width / 4}px)` }}
                />
            </>
        );
    };

    const BottomLine = ({ line }) => {
        return (
            <>
                <line
                    x1={line.x1}
                    y1={line.y}
                    x2={line.x2}
                    y2={line.y}
                    stroke="#000"
                    strokeWidth={1}
                    //  style={{ transform: `translateX(-${width / 4}px)` }}
                />
            </>
        );
    };

    return (
        <>
            <svg
                width={sankey.width}
                height={sankey.height}
                onClick={onClick}
                viewBox={`0 0 ${sankey.width} ${sankey.height}`}
                className={classes.sankeySvg}
            >
                <rect
                    width={sankey.width}
                    height={sankey.height}
                    fill={"none"}
                />
                {sankey.links.map((l, i) => {
                    if (l.type && l.type === "triangle") {
                        return <Triangle key={i} link={l} i={i} />;
                    }
                    return (
                        <path
                            className={l.class}
                            key={i}
                            d={l.path}
                            stroke={l.color}
                            fill="none"
                            opacity={l.opacity}
                            strokeWidth={l.width}
                        />
                    );
                })}
                {sankey.nodes.map((d, i) => (
                    <Node key={i} node={d} />
                ))}

                {sankey.tips.map((t, i) => (
                    <Tip key={i} tip={t} />
                ))}

                {sankey.xAxis.map((a, i) => (
                    <XAxis key={i} axis={a} />
                ))}

                {sankey.yAxis.map((a, i) => (
                    <YAxis key={i} axis={a} />
                ))}

                {sankey.lines.map((l, i) => (
                    <Line key={i} line={l} />
                ))}

                {sankey.bottomLines.map((l, i) => (
                    <BottomLine key={i} line={l} />
                ))}
            </svg>
        </>
    );
};

export default Sankey;
