import React from "react";
import styles from "./result-table-styles.module.css";

import { Button } from "../CloseButton";

const ResultTableLeft = ({
    tableName,
    tableInfo,
    investors,
    selectedInvestor,
    investmentFunds,
    selecteInvestmentFund,
    assets,
    selectedAsset,
    onEdit,
    comments,
    investor,
    asset,
    investmentFund,
    legend,
}) => {
    const legends = [
        `1: Approximate costs to cover all required service providers and setup. Please check the detailed overview for the breakdown.`,
        `2: Minimum numbers of days to launch the structure (excluding any legal draftings, negotiations etc). Please check the detailed overview for the breakdown.`,
    ];

    return (
        <div className={styles.tableLeft}>
            <div className={`${styles.tableLeftInner}`}>
                <div className={styles.tableInfo}>{tableInfo}</div>
            </div>

            <div className={styles.tableCenter}>
                <div className={`${styles.label} ${styles.labelMargin}`}>
                    <img
                        className={styles.labelImg}
                        src={"images/investor.svg"}
                        alt={""}
                    />{" "}
                    <div>
                        <h6>CH Investor</h6>
                        <span>
                            {investors &&
                                investors
                                    .find(
                                        (i) => i.investorId == selectedInvestor
                                    )
                                    ?.investor.trim()}
                            {investor && investor.trim()}
                        </span>
                    </div>
                </div>

                <div className={`${styles.label} ${styles.labelMargin}`}>
                    {(investmentFunds || investmentFund) && (
                        <>
                            <img
                                className={styles.labelImg}
                                src={"images/investment_structure.svg"}
                                alt={""}
                            />{" "}
                            <div>
                                <h6>Investment structure</h6>
                                <span>
                                    {investmentFund && investmentFund.trim()}
                                </span>
                            </div>
                        </>
                    )}
                </div>

                <div className={`${styles.label} ${styles.labelMarginLast}`}>
                    <img
                        className={styles.labelImg}
                        src={"images/asset.svg"}
                        alt={""}
                    />{" "}
                    <div>
                        <h6>CH Asset</h6>

                        <span>
                            {" "}
                            {assets &&
                                assets
                                    .find((a) => a.assetId == selectedAsset)
                                    ?.assetClass.trim()}
                            {asset && asset.trim()}
                        </span>
                    </div>
                </div>

                <Button
                    type={"button"}
                    label={"Edit"}
                    outlined={true}
                    onClick={onEdit}
                />
            </div>

            <div className={`${styles.graphTips}`}>
                <p className={styles.graphNotice}>
                    {comments
                        ? `${comments.legendId} : ${comments.fullComments}`
                        : ""}
                    {legend
                        ? legends.map((l, i) => (
                              <React.Fragment key={i}>
                                  {l} <br />
                              </React.Fragment>
                          ))
                        : ""}
                </p>
            </div>
        </div>
    );
};
export default ResultTableLeft;
