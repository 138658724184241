import styles from "./tr-styles.module.css";
import React from "react";
import { getGroupValues, getInvestmentFunds } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { setActiveFund } from "../tableSlice";
import { CurrencyFormat } from "../formats";

const TrServiceProvider = () => {
    const data = useSelector((state) => state.table.data);
    const activeFund = useSelector((state) => state.table.activeFund);

    const dispatch = useDispatch();

    const funds = getInvestmentFunds(data, "sd14");

    let max = 0;

    const fundData = {};

    for (const fund of funds) {
        const d = getGroupValues(data, "sd14", fund);
        fundData[fund] = d;
        if (d.length > max) {
            max = d.length;
        }
    }

    const trs = [];

    for (let i = 0; i < max; i++) {
        if (i === 0) {
            trs.push(
                <tr key={i}>
                    {funds &&
                        funds.map((f, key) => {
                            return (
                                <td
                                    key={key}
                                    onClick={() => dispatch(setActiveFund(f))}
                                >
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Provider</th>
                                                <th>Domicile</th>
                                                <th>Costs</th>
                                            </tr>
                                        </thead>
                                    </table>
                                </td>
                            );
                        })}
                </tr>
            );
        }

        trs.push(
            <tr>
                {funds &&
                    funds.map((f, key) => {
                        const d = fundData[f][i];

                        return (
                            <td
                                key={key}
                                className={`${styles.tdGroupRow} ${
                                    f === activeFund ? styles.activeTable : ""
                                }`}
                                onClick={() => dispatch(setActiveFund(f))}
                            >
                                <table className={`${styles.tdGroupTableRow}`}>
                                    <tbody>
                                        <tr>
                                            {d && (
                                                <td>
                                                    {d !== "" &&
                                                        d.serviceProviders}
                                                    {(d === "" || d === null) &&
                                                        "-"}
                                                </td>
                                            )}
                                            {d && (
                                                <td>
                                                    {d !== "" && d.domicile}
                                                    {(d === "" || d === null) &&
                                                        "-"}
                                                </td>
                                            )}
                                            {d && (
                                                <td>
                                                    {d !== "" &&
                                                        CurrencyFormat(d.costs)}
                                                    {(d === "" || d === null) &&
                                                        "-"}
                                                </td>
                                            )}
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        );
                    })}
            </tr>
        );
    }

    return trs.map((tr, key) => {
        return <React.Fragment key={key}>{tr}</React.Fragment>;
    });
};

export default TrServiceProvider;
