import { arc as arcShape } from "d3-shape";
import { path } from "d3-path";
import * as d3Chord from "d3-chord";
import { max, scaleLinear } from "d3";

const getNodes = (data, width, height) => {
    const legalFormNodes = getNodesByType(data, "legalForm", width, height);
    const investmentFundNodes = getNodesByType(
        data,
        "investmentFund",
        width,
        height
    );

    return [...legalFormNodes, ...investmentFundNodes];
};

const getNodesByType = (data, type, width, height) => {
    const arcGenerator = arcShape();

    let nodesEntries = {};
    data.forEach(function (d) {
        nodesEntries[type === "legalForm" ? d.legalForm : d.investmentFund] =
            (nodesEntries[
                type === "legalForm" ? d.legalForm : d.investmentFund
            ] || 0) + 1;
    });

    let nodesEntriesArray = [];
    for (const node in nodesEntries) {
        nodesEntriesArray.push({ node: node, value: nodesEntries[node] });
    }

    const nodesSum =
        nodesEntriesArray.length > 0
            ? nodesEntriesArray.reduce((a, b) => ({
                  value: a.value + b.value,
              }))
            : 0;

    const offset = 1 / (nodesEntriesArray.length * 10);
    const nodeWidth =
        (1 - offset * (nodesEntriesArray.length + 1)) / nodesSum.value;
    const result = [];

    let pos = 1;

    for (const [i, item] of nodesEntriesArray.entries()) {
        pos = pos + offset;

        const nodeData = data.find((d) => d.investmentFund === item.node);

        const node = {
            id: `node-${type}-${i}`,
            startAngle: type === "legalForm" ? -pos * Math.PI : pos * Math.PI,
            endAngle:
                type === "legalForm"
                    ? -(pos + nodeWidth * item.value) * Math.PI
                    : (pos + nodeWidth * item.value) * Math.PI,
            color: type === "legalForm" ? "#FE6F00" : "#1650B4",
            name: item.node,
            shortName: nodeData?.shortName,
            connections: item.value,
            ribbons: [],
            launchBubbles: [],
            type: type,
            group: `${item.node}-${type}`,
        };

        node.path = arcGenerator({
            innerRadius: width / 3.6 - 15,
            outerRadius: width / 3.6,
            startAngle: node.startAngle,
            endAngle: node.endAngle,
        });

        node.shadowPath = arcGenerator({
            innerRadius: width / 2.95 - 10,
            outerRadius: width / 2.95,
            startAngle: node.startAngle,
            endAngle: node.endAngle,
        });

        node.shadowPathOpacity = 0.1;

        node.text = node.shortName ? node.shortName.trim() : node.name.trim();

        const p = path();

        let arcXPos;
        let arcYPos;

        if (pos >= 1.4) {
            arcXPos =
                type === "legalForm"
                    ? -(
                          pos +
                          0.49 +
                          ((nodeWidth * item.value) / 2 +
                              (node.text.length * 0.01) / 2)
                      ) * Math.PI
                    : (pos -
                          0.49 +
                          (nodeWidth * item.value) / 2 -
                          (node.text.length * 0.011) / 2) *
                      Math.PI;

            arcYPos =
                type === "legalForm"
                    ? (pos + 0.5) * Math.PI + 0.3
                    : (pos - 0.5 + node.text.length * 0.03) * Math.PI;

            p.arc(0, 0, 235, arcXPos, arcYPos, false);
        } else {
            arcXPos =
                type === "legalForm"
                    ? -(
                          pos +
                          0.5 +
                          ((nodeWidth * item.value) / 2 -
                              (node.text.length * 0.01) / 2)
                      ) * Math.PI
                    : (pos -
                          0.505 +
                          (nodeWidth * item.value) / 2 +
                          (node.text.length * 0.011) / 2) *
                      Math.PI;

            arcYPos =
                type === "legalForm"
                    ? (pos + 0.5) * Math.PI + 0.3
                    : (pos - 0.5 + node.text.length * 0.03) * Math.PI;

            p.arc(0, 0, 245, arcXPos, arcYPos, true);
        }

        node.textPath = p;

        pos = pos + nodeWidth * item.value;
        result.push(node);
    }

    return result;
};

const getRibbons = (data, nodes) => {
    data.reverse();
    const ribbons = [];
    const ribbonGenerator = d3Chord.ribbon().radius(205);

    for (const [i, item] of data.entries()) {
        const investmentFundNode = nodes.find(
            (n) => n.name === item.investmentFund && n.type === "investmentFund"
        );
        const legalFormNode = nodes.find(
            (n) => n.name === item.legalForm && n.type === "legalForm"
        );

        let sourceRibbonWidth =
            (investmentFundNode.endAngle - investmentFundNode.startAngle) /
            investmentFundNode.connections;
        let sourceStart =
            investmentFundNode.startAngle +
            sourceRibbonWidth * investmentFundNode.ribbons.length;
        let sourceEnd = sourceStart + sourceRibbonWidth;
        sourceStart += 0.005;
        sourceEnd -= 0.005;

        let targetRibbonWidth =
            (legalFormNode.endAngle - legalFormNode.startAngle) /
            legalFormNode.connections;
        let targetStart =
            legalFormNode.startAngle +
            targetRibbonWidth * legalFormNode.ribbons.length;
        let targetEnd = targetStart + targetRibbonWidth;
        targetStart -= 0.005;
        targetEnd += 0.005;

        const investmentFundNodeCenter =
            investmentFundNode.startAngle +
            (investmentFundNode.endAngle - investmentFundNode.startAngle) / 2;
        const legalFormNodeCenter = targetStart + targetRibbonWidth / 2;

        const regulationComplexityPosition = {
            x: 200 * Math.sin(investmentFundNodeCenter),
            y: -200 * Math.cos(investmentFundNodeCenter),
        };

        const scale = scaleLinear()
            .domain([
                0,
                max(
                    data.map((d) => d.annualCosts),
                    (d) => d
                ),
            ])
            .range([0, 13]);

        const offset = scale(item.annualCosts);

        const toCommas = (value) => {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
        };

        const dot = {
            x: (300 + offset) * Math.sin(investmentFundNodeCenter),
            y: (-300 - offset) * Math.cos(investmentFundNodeCenter),
            color: "#1650B4",
            cost: `$${toCommas(item.costsToLaunchUsd)}`,
        };

        const annualCost = abbreviateNumber(item.annualCosts);

        const costToLaunchBubble = {
            x: 280 * Math.sin(investmentFundNodeCenter),
            y: -280 * Math.cos(investmentFundNodeCenter),
            color: "#1650B4",
            cost: `${annualCost}`,
            offset: offset,
            textPosition: {
                x: (280 + offset / 2) * Math.sin(investmentFundNodeCenter),
                y: (-280 - offset / 2) * Math.cos(investmentFundNodeCenter),
            },
        };

        const launchBubble = {
            x: 260 * Math.sin(legalFormNodeCenter),
            y: -260 * Math.cos(legalFormNodeCenter),
            color: "#FE6F00",
            cost: item.launchTiming,
            investmentFund: investmentFundNode.name,
            textPosition: {
                x: 280 * Math.sin(legalFormNodeCenter),
                y: -280 * Math.cos(legalFormNodeCenter),
            },
            group: `${investmentFundNode.id}-${legalFormNode.id}-bubble`,
            opacity: 0.2,
        };

        investmentFundNode.dot = dot;
        investmentFundNode.regulationComplexityPosition =
            regulationComplexityPosition;
        investmentFundNode.costToLaunchBubble = costToLaunchBubble;
        legalFormNode.launchBubbles.push(launchBubble);

        const path = ribbonGenerator({
            source: { startAngle: sourceStart, endAngle: sourceEnd },
            target: { startAngle: targetEnd, endAngle: targetStart },
        });

        const ribbon = {
            id: `ribbon-${i}`,
            path: path,
        };

        item.annualCostsFormated = abbreviateNumber(item.annualCosts);
        item.costsToLaunchUsdFormated = abbreviateNumber(item.costsToLaunchUsd);

        investmentFundNode.data = item;
        investmentFundNode.ribbons.push(ribbon);
        investmentFundNode.regulationComplexity =
            item.regulationComplexity.substr(0, 1);
        legalFormNode.ribbons.push(ribbon);
        ribbon.from = investmentFundNode;
        ribbon.to = legalFormNode;

        ribbons.push(ribbon);
    }

    return ribbons;
};

const abbreviateNumber = (value) => {
    let newValue = value;
    if (value >= 1000) {
        const suffixes = ["", "K", "M", "B", "T"];
        const suffixNum = Math.floor(value.toLocaleString().length / 4);
        let shortValue = "";
        for (let precision = 6; precision >= 4; precision--) {
            shortValue = parseFloat(
                (suffixNum != 0
                    ? value / Math.pow(1000, suffixNum)
                    : value
                ).toPrecision(precision)
            );
            let dotLessShortValue = (shortValue + "").replace(
                /[^a-zA-Z 0-9]+/g,
                ""
            );
            if (dotLessShortValue.length <= 2) {
                break;
            }
        }
        if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
        newValue = shortValue + suffixes[suffixNum];
    }
    return newValue;
};

export { getNodes, getRibbons };
