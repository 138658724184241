import { useEffect, useState } from "react";

import animateScrollTo from "animated-scroll-to";

import styles from "./comparison-table-styles.module.css";
import axios from "../../utils/axios";

import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { setData } from "./tableSlice";
import ComparisonTableHighlighted from "./ComparisonTableHighlighted";
import { CSSTransition } from "react-transition-group";

import SD1 from "./tables/SD1";
import SD2 from "./tables/SD2";
import SD3 from "./tables/SD3";
import SD4 from "./tables/SD4";
import SD5 from "./tables/SD5";
import SD6 from "./tables/SD6";
import SD9 from "./tables/SD9";
import SD10 from "./tables/SD10";
import SD11 from "./tables/SD11";
import SD12 from "./tables/SD12";

import { setShowFooter } from "../../reducers/footerSlice";

const ComparisonTable = () => {
    const data = useSelector((state) => state.table.data);

    const [triggerCollapse, setTriggerCollapse] = useState(false);
    const [allCollapsed, setAllCollapsed] = useState(true);
    const [tablesCollapsed, setTablesCollapsed] = useState(0);
    const [collapseOnClick, setCollapseOnClick] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            const params = new URLSearchParams(window.location.search);
            const data = await axios.get("/demo/full-table", {
                params: {
                    selfManaged: params.get("selfManaged"),
                    noFixedCapital: params.get("noFixedCapital"),
                    openEnded: params.get("openEnded"),
                    corporate: params.get("corporate"),
                },
            });

            dispatch(setData(data.data));
        })();
    }, []);

    useEffect(() => {
        dispatch(setShowFooter(false));
        return () => dispatch(setShowFooter(true));
    }, []);

    useEffect(() => {
        setAllCollapsed(tablesCollapsed > 0);
        dispatch(setShowFooter(tablesCollapsed > 0));
    }, [tablesCollapsed]);

    useEffect(() => {
        if (collapseOnClick) {
            setTriggerCollapse(allCollapsed);
        }
    }, [allCollapsed, collapseOnClick]);

    useEffect(() => {
        setTimeout(() => {
            setCollapseOnClick(false);
        }, 1);
    }, [allCollapsed]);

    const collapseAll = () => {
        setCollapseOnClick(true);
        setAllCollapsed(true);
        setTablesCollapsed(0);
    };

    const scrollToTop = () => {
        animateScrollTo(0);
    };

    return (
        data && (
            <>
                <div className={styles.container}>
                    <div className={styles.comparisonPageHeader}>
                        <h2>Legal structures: expanded view</h2>

                        <CSSTransition
                            in={tablesCollapsed > 0}
                            timeout={300}
                            classNames={{
                                appear: styles.fadeAnimation,
                                appearActive: styles.fadeAnimationActive,
                                appearDone: styles.fadeAnimationActive,
                                enter: styles.fadeAnimation,
                                enterActive: styles.fadeAnimationActive,
                                enterDone: styles.fadeAnimationActive,
                                exit: styles.fadeAnimation,
                                exitActive: styles.fadeAnimation,
                                exitDone: styles.fadeAnimation,
                            }}
                            unmountOnExit
                        >
                            <button onClick={collapseAll}>Collapse all</button>
                        </CSSTransition>
                    </div>

                    <SD1
                        triggerCollapse={triggerCollapse}
                        setTablesCollapsed={setTablesCollapsed}
                        tablesCollapsed={tablesCollapsed}
                        collapseOnClick={collapseOnClick}
                    />

                    <SD2
                        triggerCollapse={triggerCollapse}
                        setTablesCollapsed={setTablesCollapsed}
                        tablesCollapsed={tablesCollapsed}
                        collapseOnClick={collapseOnClick}
                    />

                    <SD3
                        triggerCollapse={triggerCollapse}
                        setTablesCollapsed={setTablesCollapsed}
                        tablesCollapsed={tablesCollapsed}
                        collapseOnClick={collapseOnClick}
                    />

                    <SD4
                        triggerCollapse={triggerCollapse}
                        setTablesCollapsed={setTablesCollapsed}
                        tablesCollapsed={tablesCollapsed}
                        collapseOnClick={collapseOnClick}
                    />

                    <SD5
                        triggerCollapse={triggerCollapse}
                        setTablesCollapsed={setTablesCollapsed}
                        tablesCollapsed={tablesCollapsed}
                        collapseOnClick={collapseOnClick}
                    />

                    <SD6
                        triggerCollapse={triggerCollapse}
                        setTablesCollapsed={setTablesCollapsed}
                        tablesCollapsed={tablesCollapsed}
                        collapseOnClick={collapseOnClick}
                    />

                    <SD9
                        triggerCollapse={triggerCollapse}
                        setTablesCollapsed={setTablesCollapsed}
                        tablesCollapsed={tablesCollapsed}
                        collapseOnClick={collapseOnClick}
                    />

                    <SD10
                        triggerCollapse={triggerCollapse}
                        setTablesCollapsed={setTablesCollapsed}
                        tablesCollapsed={tablesCollapsed}
                        collapseOnClick={collapseOnClick}
                    />

                    <SD11
                        triggerCollapse={triggerCollapse}
                        setTablesCollapsed={setTablesCollapsed}
                        tablesCollapsed={tablesCollapsed}
                        collapseOnClick={collapseOnClick}
                    />

                    <SD12
                        triggerCollapse={triggerCollapse}
                        setTablesCollapsed={setTablesCollapsed}
                        tablesCollapsed={tablesCollapsed}
                        collapseOnClick={collapseOnClick}
                    />
                </div>

                <ComparisonTableHighlighted
                    triggerCollapse={triggerCollapse}
                    setTablesCollapsed={setTablesCollapsed}
                    tablesCollapsed={tablesCollapsed}
                    collapseOnClick={collapseOnClick}
                />

                <div className={styles.backBtn}>
                    {tablesCollapsed === 0 ? (
                        <Link
                            to={{
                                pathname: "/legal-structures",
                                search: window.location.search + "&back=true",
                            }}
                        >
                            Back to short version
                        </Link>
                    ) : (
                        <button onClick={scrollToTop}>Back to top</button>
                    )}
                </div>
            </>
        )
    );
};

export default ComparisonTable;
