import "react-perfect-scrollbar/dist/css/styles.css";

import "./App.css";

import { BrowserRouter } from "react-router-dom";
import { HomepageProvider, DisclaimerContext } from "./context";

import AppRouter from "./router";
import useCheckDisclaimer from "./hooks/useCheckDisclaimer";
import { Provider } from "react-redux";
import { store } from "./store";

function App() {
    const { disclaimer, setDisclaimer, isLoading, setIsLoading } =
        useCheckDisclaimer();

    return (
        <Provider store={store}>
            <HomepageProvider>
                <DisclaimerContext.Provider
                    value={{
                        disclaimer,
                        setDisclaimer,
                        isLoading,
                        setIsLoading,
                    }}
                >
                    <BrowserRouter>
                        <AppRouter />
                    </BrowserRouter>
                </DisclaimerContext.Provider>
            </HomepageProvider>
        </Provider>
    );
}

export default App;
