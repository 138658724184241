import ComparisonTableSection from "../ComparisonTableSection";
import TableHeader from "../components/TableHeader";
import { Tr } from "../components";

import { BooleanFormat } from "../formats";

import { useSelector } from "react-redux";
import ComparisonTableSectionProvider from "../ComparisonTableSectionProvider";

const SD13 = ({
    triggerCollapse,
    setTablesCollapsed,
    tablesCollapsed,
    collapseOnClick,
    setInitCollapsed,
    initCollapsed,
}) => {
    const data = useSelector((state) => state.table.data);

    return data && data.sd13 ? (
        <ComparisonTableSectionProvider>
            <ComparisonTableSection
                triggerCollapse={triggerCollapse}
                setTablesCollapsed={setTablesCollapsed}
                tablesCollapsed={tablesCollapsed}
                collapseOnClick={collapseOnClick}
                setInitCollapsed={setInitCollapsed}
                initCollapsed={initCollapsed}
                title={"Asset manager"}
                expandable={false}
            >
                <table>
                    <TableHeader sdKey={"sd13"} />

                    <tbody>
                        <Tr
                            skipFirstCol={true}
                            featName={"Global regulation type"}
                            dataKey={"sd13"}
                            colKey={"globalRegulationType"}
                        />

                        <Tr
                            skipFirstCol={true}
                            featName={"Fund regime"}
                            dataKey={"sd13"}
                            colKey={"fundRegime"}
                        />
                        <Tr
                            skipFirstCol={true}
                            featName={"Self managed"}
                            dataKey={"sd13"}
                            colKey={"ifSelfManaged"}
                            formatFunc={BooleanFormat}
                        />
                        <Tr
                            skipFirstCol={true}
                            featName={"Asset manager"}
                            dataKey={"sd13"}
                            colKey={"assetManager"}
                        />
                        <Tr
                            skipFirstCol={true}
                            featName={"Asset manager options"}
                            dataKey={"sd13"}
                            colKey={"assetManagerOptions"}
                        />
                    </tbody>
                </table>
            </ComparisonTableSection>
        </ComparisonTableSectionProvider>
    ) : null;
};

export default SD13;
