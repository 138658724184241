import ComparisonTableSection from "../ComparisonTableSection";
import TableHeader from "../components/TableHeader";
import { Tr } from "../components";

import {
    BooleanFormat,
    DescriptionProgressFormat,
    PeopleFormat,
} from "../formats";

import { useSelector } from "react-redux";
import { HoldersFormat } from "../formats";
import TrProvider from "../components/TrProvider";
import ComparisonTableSectionProvider from "../ComparisonTableSectionProvider";

const SD2 = ({
    triggerCollapse,
    setTablesCollapsed,
    tablesCollapsed,
    collapseOnClick,
}) => {
    const data = useSelector((state) => state.table.data);

    return data && data.sd2 ? (
        <ComparisonTableSectionProvider>
            <ComparisonTableSection
                triggerCollapse={triggerCollapse}
                setTablesCollapsed={setTablesCollapsed}
                tablesCollapsed={tablesCollapsed}
                collapseOnClick={collapseOnClick}
                title={"Structuring features"}
            >
                <table>
                    <TableHeader sdKey={"sd2"} />

                    <tbody>
                        <Tr
                            featName={"Legal form"}
                            dataKey={"sd2"}
                            colKey={"legalForm"}
                        />

                        <Tr
                            featName={"Umbrella / segregated sub-funds"}
                            dataKey={"sd2"}
                            colKey={"umbrella"}
                            formatFunc={BooleanFormat}
                        />

                        <Tr
                            featName={"Cross sub-funds investment"}
                            dataKey={"sd2"}
                            colKey={"umbrellaCrossInvestments"}
                            formatFunc={BooleanFormat}
                        />

                        <Tr
                            featName={"Classes of shares"}
                            dataKey={"sd2"}
                            colKey={"classesOfShares"}
                            formatFunc={BooleanFormat}
                        />

                        <Tr
                            featName={"Master - feader"}
                            dataKey={"sd2"}
                            colKey={"feeder"}
                            formatFunc={BooleanFormat}
                        />

                        <Tr
                            featName={"Listing"}
                            dataKey={"sd2"}
                            colKey={"listing"}
                            formatFunc={BooleanFormat}
                        />

                        <Tr
                            featName={"Short selling"}
                            dataKey={"sd2"}
                            colKey={"shortSelling"}
                            formatFunc={BooleanFormat}
                        />

                        <Tr
                            featName={"Borrowing"}
                            dataKey={"sd2"}
                            colKey={"borrowing"}
                            formatFunc={BooleanFormat}
                        />

                        <Tr
                            featName={"Side pockets"}
                            dataKey={"sd2"}
                            colKey={"sidePockets"}
                            formatFunc={BooleanFormat}
                        />

                        <Tr
                            featName={"Open- or closed-ended"}
                            dataKey={"sd2"}
                            colKey={"ifOpenEnded"}
                        />
                        <Tr
                            featName={"Min number of shareholders"}
                            dataKey={"sd2"}
                            colKey={"minNumOfShareholders"}
                            formatFunc={PeopleFormat}
                        />
                        <Tr
                            featName={"Max number of shareholders"}
                            dataKey={"sd2"}
                            colKey={"maxNumOfShareholders"}
                            formatFunc={HoldersFormat}
                        />
                        <TrProvider>
                            <Tr
                                featName={"Risk diversification requirements"}
                                dataKey={"sd2"}
                                colKey={"riskDiversification"}
                                formatFunc={DescriptionProgressFormat}
                                progressCol={"riskDiversificationBar"}
                                descriptionCol={"riskDiversificationDetails"}
                            />
                        </TrProvider>
                    </tbody>
                </table>
            </ComparisonTableSection>
        </ComparisonTableSectionProvider>
    ) : null;
};

export default SD2;
