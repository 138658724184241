import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    showFooter: true,
};

export const footerSlice = createSlice({
    name: "footer",
    initialState,
    reducers: {
        setShowFooter: (state, action) => {
            state.showFooter = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setShowFooter } = footerSlice.actions;

export default footerSlice.reducer;
