// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button-styles_close__2b0Su {\n    background: transparent;\n    border: 0;\n    position: absolute;\n    z-index: 4;\n    top: 14px;\n    right: 14px;\n    padding: 2px;\n}\n", "",{"version":3,"sources":["webpack://src/shared/Button/button-styles.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,SAAS;IACT,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,WAAW;IACX,YAAY;AAChB","sourcesContent":[".close {\n    background: transparent;\n    border: 0;\n    position: absolute;\n    z-index: 4;\n    top: 14px;\n    right: 14px;\n    padding: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"close": "button-styles_close__2b0Su"
};
export default ___CSS_LOADER_EXPORT___;
