const getUnique = (value, index, self) => {
    return self.indexOf(value) === index;
};

const getGroupValues = (data, key, investmentFund) => {
    return data[key].filter((d) => d.investmentFund?.trim() === investmentFund);
};

const getGroupValuesByLegalForm = (data, key, fund, colKey, colValue) => {
    return data[key].filter(
        (d) => d.investmentFund?.trim() === fund && d[colKey] === colValue
    );
};

const getInvestmentFunds = (data, key) => {
    return data[key].map((d) => d.investmentFund?.trim()).filter(getUnique);
};

export {
    getUnique,
    getGroupValues,
    getGroupValuesByLegalForm,
    getInvestmentFunds,
};
