import Header from "./components/Header/Header";

const Main = (props) => {
    const { children } = props;

    return (
        <>
            <Header />

            <main>{children}</main>
        </>
    );
};

export default Main;
