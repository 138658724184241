import styles from "./formats-styles.module.css";

const HoldersFormat = (d) => {
    let num = parseInt(d);
    if (!d || (d && isNaN(num))) return "-";

    num = new Intl.NumberFormat("en-US").format(num);

    return (
        <div className={styles.flex}>
            <img alt={""} src={"/images/user.svg"} /> x {num}
        </div>
    );
};

export default HoldersFormat;
