import ComparisonTableSection from "../ComparisonTableSection";
import TableHeader from "../components/TableHeader";
import { Tr } from "../components";

import {
    BooleanFormat,
    DescriptionFormat,
    DescriptionProgressFormat,
    PeopleFormat,
    PercentFormat,
} from "../formats";

import { useSelector } from "react-redux";
import TrProvider from "../components/TrProvider";
import ComparisonTableSectionProvider from "../ComparisonTableSectionProvider";

const SD1 = ({
    triggerCollapse,
    setTablesCollapsed,
    tablesCollapsed,
    collapseOnClick,
}) => {
    const data = useSelector((state) => state.table.data);

    return data && data.sd1 ? (
        <ComparisonTableSectionProvider>
            <ComparisonTableSection
                triggerCollapse={triggerCollapse}
                setTablesCollapsed={setTablesCollapsed}
                tablesCollapsed={tablesCollapsed}
                collapseOnClick={collapseOnClick}
                title={"Legal & regulatory features"}
            >
                <table>
                    <TableHeader sdKey={"sd1"} />

                    <tbody>
                        <Tr
                            featName={"Legal form"}
                            dataKey={"sd1"}
                            colKey={"legalForm"}
                        />

                        <Tr
                            featName={"Full name"}
                            dataKey={"sd1"}
                            colKey={"fullName"}
                        />

                        <Tr
                            featName={"Governing law"}
                            dataKey={"sd1"}
                            colKey={"governingLaw"}
                        />

                        <Tr
                            featName={"Domicile"}
                            dataKey={"sd1"}
                            colKey={"domicileName"}
                        />

                        <Tr
                            featName={"Legal type"}
                            dataKey={"sd1"}
                            colKey={"legalType"}
                        />

                        <Tr
                            featName={"Regulation type"}
                            dataKey={"sd1"}
                            colKey={"regulationType"}
                        />

                        <Tr
                            featName={"Regulation name"}
                            dataKey={"sd1"}
                            colKey={"regulationName"}
                        />

                        <TrProvider>
                            <Tr
                                featName={"Level of regulation"}
                                dataKey={"sd1"}
                                colKey={"levelOfRegulation"}
                                progressCol={"levelOfRegulationBar"}
                                formatFunc={DescriptionProgressFormat}
                            />
                        </TrProvider>

                        <Tr
                            featName={"Alternative investment fund?"}
                            dataKey={"sd1"}
                            colKey={"ifAif"}
                            formatFunc={BooleanFormat}
                        />

                        <TrProvider>
                            <Tr
                                featName={"Qualification as aif"}
                                dataKey={"sd1"}
                                formatFunc={DescriptionFormat}
                                colKey={"qualificationAsAif"}
                            />
                        </TrProvider>

                        <Tr
                            featName={"Has legal personality?"}
                            dataKey={"sd1"}
                            colKey={"ifHasLegalPersonality"}
                            formatFunc={BooleanFormat}
                        />

                        <Tr
                            featName={"General partner has limited liability?"}
                            dataKey={"sd1"}
                            colKey={"ifGpHasLimitedLiability"}
                            formatFunc={BooleanFormat}
                        />

                        <Tr
                            featName={
                                "Shareholder or limited partner has limited liability?"
                            }
                            dataKey={"sd1"}
                            colKey={"ifShareholderOrLpHasLimitedLiability"}
                            formatFunc={BooleanFormat}
                        />

                        <Tr
                            featName={"Self managed?"}
                            dataKey={"sd1"}
                            colKey={"ifSelfManaged"}
                            formatFunc={BooleanFormat}
                        />

                        <Tr
                            featName={"Min number directors"}
                            dataKey={"sd1"}
                            colKey={"minNumDirectors"}
                            formatFunc={PeopleFormat}
                        />

                        <Tr
                            featName={"Min number directors for local GP"}
                            dataKey={"sd1"}
                            colKey={"minNumDirectorsForLocalGp"}
                            formatFunc={PeopleFormat}
                        />

                        <Tr
                            featName={"Thin capitalization"}
                            dataKey={"sd1"}
                            colKey={"thinCapitalization"}
                            formatFunc={PercentFormat}
                        />
                    </tbody>
                </table>
            </ComparisonTableSection>
        </ComparisonTableSectionProvider>
    ) : null;
};

export default SD1;
