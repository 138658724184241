const CurrencyFormat = (d) => {
    if (!d) return "-";

    const arr = d.split(" ");

    if (!isNaN(parseInt(arr[0])) && arr.length === 2) {
        d =
            new Intl.NumberFormat("en-US").format(arr[0]).replace(/,/g, "'") +
            " " +
            arr[1];
    }

    return d;
};
export default CurrencyFormat;
