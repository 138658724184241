import styles from "./tr-styles.module.css";
import {
    getGroupValues,
    getGroupValuesByLegalForm,
    getInvestmentFunds,
    getUnique,
} from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { setActiveFund } from "../tableSlice";
import { useContext, useEffect, useState } from "react";

import { TableDescriptionContext } from "../context";
import { TrContext } from "../context/TrContext";

const Tr = ({
    featName,
    dataKey,
    fundKey,
    colKey,
    formatFunc,
    formatData,
    progressCol,
    firstCol,
    skipFirstCol,
    passData,
    descriptionCol,
}) => {
    const [changeRows, setChangeRows] = useState(false);

    const data = useSelector((state) => state.table.data);
    const activeFund = useSelector((state) => state.table.activeFund);

    const dispatch = useDispatch();

    const {
        expandedRows,
        setExpandedRows,
        globalExpanded,
        setTotalExpandableRows,
        totalExpandableRows,
    } = useContext(TableDescriptionContext);
    const { hasDescription, expanded, setExpanded } = useContext(TrContext);

    useEffect(() => {
        setTimeout(() => {
            setChangeRows(hasDescription);
        });
    }, [hasDescription]);

    useEffect(() => {
        if (changeRows) {
            setTotalExpandableRows(totalExpandableRows + 1);
            setChangeRows(false);
        }
    }, [changeRows, totalExpandableRows]);

    useEffect(() => {
        setExpanded(globalExpanded);
    }, [globalExpanded]);

    fundKey = fundKey || dataKey;
    firstCol = firstCol || "legalForm";

    const funds = getInvestmentFunds(data, fundKey);

    const toggleExpand = () => {
        if (expanded) {
            setExpandedRows(expandedRows - 1);
        } else {
            setExpandedRows(expandedRows + 1);
        }

        setExpanded(!expanded);
    };

    return (
        <tr
            className={`${expanded ? "rowExpanded" : ""} ${
                colKey === "legalForm" ? styles.fixedRow : ""
            }`}
        >
            <td>
                {featName}
                {hasDescription && (
                    <>
                        <br />
                        <img
                            alt={""}
                            src={"/images/chevron-down-lg.svg"}
                            onClick={toggleExpand}
                            className={"rowExpandedImg"}
                        />
                    </>
                )}
            </td>
            {funds &&
                funds.map((f, i) => {
                    const tdData = [];

                    if (skipFirstCol) {
                        const d = getGroupValues(data, dataKey, f)
                            .map((d) => d[colKey])
                            .filter(getUnique);
                        tdData.push(
                            ...d.map((col) => {
                                return {
                                    data: col,
                                    colspan: 1,
                                };
                            })
                        );
                    } else {
                        const legalForm = getGroupValues(data, dataKey, f)
                            .map((d) => d[firstCol])
                            .filter(getUnique);

                        if (firstCol === colKey) {
                            for (const lf of legalForm) {
                                tdData.push({
                                    data: lf,
                                    colspan: 1,
                                });
                            }
                        } else {
                            let prevLfData = "init";
                            let colspan = 0;
                            for (let j = 0; j < legalForm.length; j++) {
                                const lfData = getGroupValuesByLegalForm(
                                    data,
                                    dataKey,
                                    f,
                                    firstCol,
                                    legalForm[j]
                                )
                                    .map((d) => d[colKey])
                                    .filter(getUnique)[0];

                                if (
                                    prevLfData === "init" ||
                                    prevLfData === lfData
                                ) {
                                    colspan++;

                                    if (j === legalForm.length - 2) {
                                        colspan++;
                                        tdData.push({
                                            data: lfData,
                                            colspan,
                                        });
                                    }
                                } else {
                                    tdData.push({
                                        data: prevLfData,
                                        colspan,
                                    });

                                    if (j === legalForm.length - 1) {
                                        tdData.push({
                                            data: lfData,
                                            colspan: 1,
                                        });
                                    }

                                    colspan = 0;
                                }

                                prevLfData = lfData;
                            }
                        }
                    }

                    return (
                        <td
                            key={`row-${i}`}
                            className={`${styles.tdGroup} ${
                                f === activeFund ? styles.activeTable : ""
                            }`}
                        >
                            <table
                                className={styles.tdGroupTable}
                                onClick={() => dispatch(setActiveFund(f))}
                            >
                                <tbody>
                                    <tr>
                                        {tdData.map((td, key) => {
                                            const d = td.data;
                                            const colspan = td.colspan;

                                            if (progressCol) {
                                                if (!formatData) {
                                                    formatData = {};
                                                }
                                                formatData.progressVal =
                                                    getGroupValues(
                                                        data,
                                                        dataKey,
                                                        f
                                                    )
                                                        .map(
                                                            (d) =>
                                                                d[progressCol]
                                                        )
                                                        .filter(getUnique)[0];
                                            }

                                            if (descriptionCol) {
                                                formatData.descriptionVal =
                                                    getGroupValues(
                                                        data,
                                                        dataKey,
                                                        f
                                                    )
                                                        .map(
                                                            (d) =>
                                                                d[
                                                                    descriptionCol
                                                                ]
                                                        )
                                                        .filter(getUnique)[0];
                                            }

                                            return (
                                                <td key={key} colSpan={colspan}>
                                                    {formatFunc ? (
                                                        <>
                                                            {passData ? (
                                                                <>
                                                                    {formatFunc(
                                                                        data,
                                                                        d,
                                                                        dataKey,
                                                                        colKey
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {formatFunc &&
                                                                        formatData &&
                                                                        formatFunc(
                                                                            d,
                                                                            formatData
                                                                        )}

                                                                    {formatFunc &&
                                                                        !formatData &&
                                                                        formatFunc(
                                                                            d
                                                                        )}
                                                                </>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {d === "" ||
                                                            d === null
                                                                ? "-"
                                                                : d}
                                                        </>
                                                    )}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    );
                })}
        </tr>
    );
};

export default Tr;
