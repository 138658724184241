import styles from "./selection-popup-styles.module.css";
import { CloseButton } from "../Button";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "../../utils/axios";
import { Response } from "../Response";

const SelectionPopup = ({ onClose, onSubmit, useInvestmentStructure }) => {
    const [investors, setInvestors] = useState([]);
    const [assets, setAssets] = useState([]);
    const [investmentFunds, setInvestmentFunds] = useState([]);
    const [showInvalidPopup, setShowInvalidPopup] = useState(false);
    const [selectedData, setSelectedData] = useState({
        investor: "7",
        asset: "7",
        investmentFund: "2",
    });

    const history = useHistory();

    useEffect(() => {
        (async () => {
            await getInvestors();
            await getAssets();

            if (useInvestmentStructure) {
                await getInvestmentFunds();
            }
        })();
    }, []);

    const getOpenEnded = async (assetClass) => {
        const data = await axios.get("/check-open-ended", {
            params: {
                assetClass: assetClass,
            },
        });

        return data.data;
    };

    const getLegalSelection = async (
        investor,
        assetClass,
        legalType,
        openEnded
    ) => {
        const data = await axios.get(
            `/check-legal-selection?investorId=${investor}&assetClass=${assetClass}&legalType=${legalType}&openEnded=${openEnded}`
        );

        return data.data;
    };

    const getInvestors = async () => {
        const investorsData = await axios.get(`/investors`);
        setInvestors(investorsData.data);

        return investorsData.data;
    };

    const getAssets = async () => {
        let assetsData = {};

        if (history.location.pathname.includes("deal-taxes")) {
            assetsData = await axios.get(`/deal-taxes-assets`);
        } else if (history.location.pathname.includes("legal-structures")) {
            assetsData = await axios.get(`/legal-structures-assets`);
        }

        setAssets(assetsData.data);

        return assetsData.data;
    };

    const getInvestmentFunds = async () => {
        const investmentFundData = await axios.get(`/investment-fund`);
        setInvestmentFunds(investmentFundData.data);

        return investmentFundData.data;
    };

    const handleSelect = (prop) => (event) => {
        setShowInvalidPopup(true);
        // setSelectedData({ ...selectedData, [prop]: event.target.value });
    };

    const handleFormSubmission = async (event) => {
        event.preventDefault();

        let continueSubmit = useInvestmentStructure;
        let openEnded;

        if (!useInvestmentStructure) {
            const assetClass = assets.find(
                (a) => a.assetId.toString() === selectedData.asset.toString()
            )?.assetClass;

            openEnded = await getOpenEnded(assetClass);
            const legalSelection = await getLegalSelection(
                selectedData.investor,
                assetClass,
                openEnded.legalType,
                openEnded.ifRecommendedOpenEnded
            );

            continueSubmit = legalSelection.success;
        }

        if (!continueSubmit) return;

        onSubmit(selectedData, {
            assets,
            investors,
            investmentFunds,
            openEnded,
        });
    };

    const handleClose = (event) => {
        if (
            selectedData.asset &&
            selectedData.investor &&
            (!useInvestmentStructure ||
                (useInvestmentStructure &&
                    selectedData.investmentFund &&
                    investmentFunds))
        ) {
            // onClose(selectedData);
            handleFormSubmission(event);
        }
    };

    return (
        <div className={styles.modal}>
            <form
                className={styles.selectionBox}
                onSubmit={handleFormSubmission}
            >
                <CloseButton onClick={handleClose} />

                <h3 className={styles.selectionBoxTitle}>
                    Make your selection
                </h3>

                {useInvestmentStructure ? (
                    <h6 className={styles.selectionBoxTitleNotice}>
                        You can choose your investor type, investment structure
                        and asset type.
                        <br />
                        Only one combination of investor - investment structure
                        - asset is available in the demo.
                    </h6>
                ) : (
                    <h6 className={styles.selectionBoxTitleNotice}>
                        You can choose your investor type and asset type.
                        <br />
                        Only one combination of investor - asset is available in
                        the demo.
                    </h6>
                )}

                {/* ID 3.4 */}
                <h6 className={styles.label}>
                    <img
                        className={styles.labelImg}
                        src={"images/investor.svg"}
                        alt={""}
                    />{" "}
                    Swiss Investor
                </h6>

                {/* ID 3.A */}
                <div className={styles.investors}>
                    {investors.map((i, key) => {
                        return (
                            <label
                                key={key}
                                className={`${styles.investorSelect} ${
                                    i.investorId != 7 ? styles.disabled : ""
                                } ${
                                    i.investorId == selectedData.investor
                                        ? styles.selected
                                        : ""
                                }`}
                                onClick={() => {
                                    setShowInvalidPopup(true);
                                }}
                            >
                                <input
                                    type={"radio"}
                                    name={"investor-select"}
                                    value={i.investorId}
                                    checked={
                                        i.investorId == selectedData.investor
                                    }
                                    disabled={i.investorId != 7}
                                    onChange={handleSelect("investor")}
                                    className={styles.investorSelectInput}
                                />

                                <h5 className={styles.investorSelectTitle}>
                                    {i.investor}
                                </h5>

                                <p className={styles.investorSelectInfo}>
                                    {i.description}
                                </p>
                            </label>
                        );
                    })}
                </div>

                {/* ID 3.C */}

                <div className={styles.selectRow}>
                    {useInvestmentStructure && investmentFunds && (
                        <>
                            <div>
                                <h6 className={styles.label}>
                                    Investment Structure
                                </h6>

                                <div className={styles.structureBar}>
                                    <img
                                        className={styles.labelImg}
                                        src={"images/investment_structure.svg"}
                                        alt={""}
                                    />{" "}
                                    <select
                                        name={"investment-funds"}
                                        id={"investment-funds"}
                                        onChange={handleSelect(
                                            "investmentFund"
                                        )}
                                        value={
                                            selectedData.investmentFund || ""
                                        }
                                    >
                                        <option value={""} disabled={true}>
                                            Select
                                        </option>
                                        {investmentFunds.map((if2, key) => {
                                            return (
                                                <option
                                                    key={key}
                                                    value={if2.investmentFundId}
                                                >
                                                    {if2.investmentFund}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>

                            {selectedData.investmentFund && (
                                <div className={styles.selectedInfo}>
                                    <h5 className={styles.selectedTitle}>
                                        {investmentFunds &&
                                            investmentFunds.find(
                                                (if2) =>
                                                    if2.investmentFundId ==
                                                    selectedData.investmentFund
                                            )?.investmentFund}
                                    </h5>

                                    <p className={styles.investorSelectInfo}>
                                        {investmentFunds &&
                                            investmentFunds.find(
                                                (if2) =>
                                                    if2.investmentFundId ==
                                                    selectedData.investmentFund
                                            )?.fullName}
                                    </p>
                                </div>
                            )}
                        </>
                    )}
                </div>

                <div className={styles.selectRow}>
                    {/* ID 3.5 */}
                    <div>
                        <h6 className={styles.label}>swiss asset</h6>
                        <div className={styles.structureBar}>
                            <img
                                className={styles.labelImg}
                                src={"images/asset.svg"}
                                alt={""}
                            />{" "}
                            {/* ID 3.B */}
                            <select
                                name={"asset-select"}
                                id={"asset-select"}
                                onChange={handleSelect("asset")}
                                value={selectedData.asset || ""}
                            >
                                <option value={""} disabled={true}>
                                    Select
                                </option>
                                {assets.map((i, key) => {
                                    return (
                                        <option key={key} value={i.assetId}>
                                            {i.assetClass}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>

                    {/* ID 3.7 */}

                    {selectedData.asset && (
                        <div className={styles.selectedInfo}>
                            <h5 className={styles.selectedTitle}>
                                {assets &&
                                    assets.find(
                                        (a) => a.assetId == selectedData.asset
                                    )?.assetClass}
                            </h5>

                            <p className={styles.investorSelectInfo}>
                                {assets &&
                                    assets.find(
                                        (a) => a.assetId == selectedData.asset
                                    )?.description}
                            </p>
                        </div>
                    )}
                </div>

                <div className={styles.submitBtn}>
                    <button
                        type={"submit"}
                        disabled={
                            !selectedData.asset ||
                            !selectedData.investor ||
                            (!selectedData.investmentFund &&
                                investmentFunds &&
                                useInvestmentStructure)
                        }
                    >
                        Submit
                    </button>
                </div>

                {showInvalidPopup && (
                    <div className={styles.response}>
                        <Response
                            type={"error"}
                            aligned={true}
                            setResponse={setShowInvalidPopup}
                        >
                            Only one combination of investor
                            {useInvestmentStructure && " - fund"} - asset is
                            available in the demo.
                        </Response>
                    </div>
                )}
            </form>
        </div>
    );
};

export default SelectionPopup;
