import styles from "./deal-taxes-expanded.module.css";
import { useEffect, useState } from "react";
import axios from "../../utils/axios";
import { calculateTaxExpanded } from "./utils";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";

const DealTaxesExpanded = () => {
    const [data, setData] = useState();

    useEffect(() => {
        (async () => {
            const params = new URLSearchParams(window.location.search);
            const data = await axios.get("/demo/sankey", {
                params: {
                    openEnded: params.get("openEnded"),
                    corporate: params.get("corporate"),
                    partnership: params.get("partnership"),
                    booked: params.get("booked"),
                },
            });

            const taxResult = calculateTaxExpanded(data.data.result);
            setData(taxResult);
        })();
    }, []);

    return (
        <>
            <div className={styles.container}>
                <div className={styles.pageHeader}>
                    <h2>Tax review: expanded view</h2>
                </div>
                <PerfectScrollbar className={`${styles.sectionWrapper}`}>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th>Stage / event</th>
                                <th>Tax</th>
                                <th>Rate</th>
                                <th>Comments / exemptions</th>
                                <th className={styles.empty}></th>
                                <th>Taxes</th>
                                <th>Dollar amounts</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data &&
                                data.map((d, index) =>
                                    d.value === 0 && !d.amount ? null : (
                                        <tr key={index}>
                                            <td>{d.stage}</td>
                                            <td>{d.tax}</td>
                                            <td>{d.rate}</td>
                                            <td className={styles.comments}>
                                                {d.comments}
                                            </td>
                                            <td
                                                className={`${styles.bold} ${
                                                    styles.left
                                                } ${
                                                    d.highlight
                                                        ? styles.highlight
                                                        : ""
                                                } ${
                                                    d.highlightRed
                                                        ? styles.highlightRed
                                                        : ""
                                                }`}
                                            >
                                                {d.exemptions}
                                            </td>
                                            <td
                                                className={`${
                                                    d.highlight
                                                        ? styles.highlight
                                                        : ""
                                                } ${
                                                    d.highlightRed
                                                        ? styles.highlightRed
                                                        : ""
                                                }`}
                                            >
                                                {d.taxesFormula}
                                            </td>
                                            <td
                                                className={`${styles.bold} ${
                                                    styles.right
                                                } ${
                                                    d.highlight
                                                        ? styles.highlight
                                                        : ""
                                                } ${
                                                    d.highlightRed
                                                        ? styles.highlightRed
                                                        : ""
                                                }`}
                                            >
                                                {d.amount}
                                            </td>
                                        </tr>
                                    )
                                )}
                        </tbody>
                    </table>
                </PerfectScrollbar>

                <Link
                    to={"/deal-taxes" + window.location.search + "&back=true"}
                    className={styles.btn}
                >
                    Back to short version
                </Link>
            </div>
        </>
    );
};

export { DealTaxesExpanded };
