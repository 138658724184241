import ComparisonTableSection from "../ComparisonTableSection";
import TableHeader from "../components/TableHeader";
import { TrServiceProvider } from "../components";

import { useSelector } from "react-redux";
import ComparisonTableSectionProvider from "../ComparisonTableSectionProvider";

const SD14 = ({
    triggerCollapse,
    setTablesCollapsed,
    tablesCollapsed,
    collapseOnClick,
    setInitCollapsed,
    initCollapsed,
}) => {
    const data = useSelector((state) => state.table.data);

    return data && data.sd14 ? (
        <ComparisonTableSectionProvider>
            <ComparisonTableSection
                title={"Required service providers"}
                row={true}
                triggerCollapse={triggerCollapse}
                setTablesCollapsed={setTablesCollapsed}
                tablesCollapsed={tablesCollapsed}
                collapseOnClick={collapseOnClick}
                setInitCollapsed={setInitCollapsed}
                initCollapsed={initCollapsed}
                expandable={false}
                excludeFeat={true}
            >
                <table>
                    <TableHeader sdKey={"sd14"} excludeFeat={true} />

                    <tbody>
                        <TrServiceProvider
                            featName={"Provider"}
                            colKey={"serviceProviders"}
                        />
                    </tbody>
                </table>
            </ComparisonTableSection>
        </ComparisonTableSectionProvider>
    ) : null;
};

export default SD14;
