import ComparisonTableSection from "../ComparisonTableSection";
import TableHeader from "../components/TableHeader";
import { Tr } from "../components";

import {
    CurrencyFormat,
    DaysFormat,
    DescriptionCurrencyFormat,
    DescriptionFormat,
} from "../formats";

import { useSelector } from "react-redux";
import TrProvider from "../components/TrProvider";
import ComparisonTableSectionProvider from "../ComparisonTableSectionProvider";

const SD9 = ({
    triggerCollapse,
    setTablesCollapsed,
    tablesCollapsed,
    collapseOnClick,
}) => {
    const data = useSelector((state) => state.table.data);

    return data && data.sd9 ? (
        <ComparisonTableSectionProvider>
            <ComparisonTableSection
                triggerCollapse={triggerCollapse}
                setTablesCollapsed={setTablesCollapsed}
                tablesCollapsed={tablesCollapsed}
                collapseOnClick={collapseOnClick}
                title={"Costs and timing"}
                expandable={false}
            >
                <table>
                    <TableHeader sdKey={"sd9"} />

                    <tbody>
                        <Tr
                            featName={"Legal form"}
                            dataKey={"sd9"}
                            colKey={"legalForm"}
                        />

                        <Tr
                            featName={"Legal fee drafting documents"}
                            dataKey={"sd9"}
                            colKey={"legalFeeDraftingDocuments"}
                            formatFunc={CurrencyFormat}
                        />

                        <Tr
                            featName={"Legal structuring advise"}
                            dataKey={"sd9"}
                            colKey={"legalStructuringAdvise"}
                            formatFunc={CurrencyFormat}
                        />

                        <Tr
                            featName={
                                "Regulator examination and registration fee"
                            }
                            dataKey={"sd9"}
                            colKey={"regulatorExaminationAndRegistrationFee"}
                            formatFunc={CurrencyFormat}
                        />
                        <Tr
                            featName={"Incorporation notary fee"}
                            dataKey={"sd9"}
                            colKey={"incorporationNotaryFee"}
                            formatFunc={CurrencyFormat}
                        />

                        <TrProvider>
                            <Tr
                                featName={"Incorporation notary fee comments"}
                                dataKey={"sd9"}
                                colKey={"incorporationNotaryFeeComments"}
                                formatFunc={DescriptionFormat}
                            />
                        </TrProvider>

                        <Tr
                            featName={"Incorporation government fee"}
                            dataKey={"sd9"}
                            colKey={"incorporationGovernmentFee"}
                            formatFunc={CurrencyFormat}
                        />
                        <TrProvider>
                            <Tr
                                featName={"Total setup costs"}
                                dataKey={"sd9"}
                                colKey={"totalSetupCost"}
                                formatFunc={DescriptionCurrencyFormat}
                                passData={true}
                            />
                        </TrProvider>
                        <Tr
                            featName={"Directors fee"}
                            dataKey={"sd9"}
                            colKey={"directorsFee"}
                            formatFunc={CurrencyFormat}
                        />

                        <Tr
                            featName={"Government annual fee"}
                            dataKey={"sd9"}
                            colKey={"governmentAnnualFee"}
                            formatFunc={CurrencyFormat}
                        />

                        <Tr
                            featName={"Regulator annual fee"}
                            dataKey={"sd9"}
                            colKey={"regulatorAnnualFee"}
                            formatFunc={CurrencyFormat}
                        />

                        <Tr
                            featName={"Total annual structuring fee"}
                            dataKey={"sd9"}
                            colKey={"totalAnnualStructureCost"}
                            formatFunc={DescriptionCurrencyFormat}
                            passData={true}
                        />

                        <Tr
                            featName={"Incorporation time (days)"}
                            dataKey={"sd9"}
                            colKey={"incorporationTime"}
                            formatFunc={DaysFormat}
                        />
                        <Tr
                            featName={"Authorization time (days)"}
                            dataKey={"sd9"}
                            colKey={"authorizationTime"}
                            formatFunc={DaysFormat}
                        />
                    </tbody>
                </table>
            </ComparisonTableSection>
        </ComparisonTableSectionProvider>
    ) : null;
};

export default SD9;
