import React, { useEffect, useState } from "react";

import styles from "./checkbox-styles.module.css";

const Checkbox = ({
    label,
    name,
    value,
    checked,
    disabled,
    info,
    onChange,
}) => {
    const [isChecked, setIsChecked] = useState(checked);

    const handleChange = (event) => {
        setIsChecked(event.target.checked);

        onChange(event);
    };

    useEffect(() => {
        if (checked !== isChecked) {
            setIsChecked(checked);
        }
    }, [checked]);

    return (
        <label className={`${styles.label} ${disabled ? styles.disabled : ""}`}>
            <input
                type={"checkbox"}
                name={name}
                value={value}
                defaultChecked={checked}
                onChange={handleChange}
                className={styles.input}
                disabled={disabled}
            />
            <span
                className={`${styles.checkbox} ${
                    isChecked ? styles.checked : ""
                }`}
            />

            <span className={styles.label}>{label}</span>

            {info && (
                <span
                    className={`${styles.tip} ${
                        disabled ? styles.disabled : ""
                    }`}
                >
                    <span className={styles.tipBtn}>
                        <span className={styles.tipBtnImg}>
                            <img alt={""} src={"/images/info.png"} />
                            <img alt={""} src={"/images/info-active.png"} />
                        </span>
                    </span>

                    <p className={styles.tipBox}>{info}</p>
                </span>
            )}
        </label>
    );
};

export default Checkbox;
