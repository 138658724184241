import React from "react";

import { Switch, useHistory } from "react-router-dom";

import useEventListener from "@use-it/event-listener";

import Main from "./layouts/Main";
import MainWithFooter from "./layouts/MainWithFooter";
import RouteWithLayout from "./layouts/RouteWithLayout";
import PrivateRouteWithLayout from "./layouts/PrivateRouteWithLayout";

import { LegalStructures } from "./pages/LegalStructures";
import { ComparisonTable } from "./pages/ComparisonTable";
import { DealTaxes } from "./pages/DealTaxes";
import { Disclaimer } from "./pages/Disclaimer";
import { DealTaxesExpanded } from "./pages/DealTaxesExpanded";
import Redirect from "react-router-dom/es/Redirect";

const BACK_KEYS = ["Delete", "Backspace"];

const AppRouter = () => {
    const history = useHistory();

    function handler({ key }) {
        const path = history.location.pathname;

        if (path === "/" || path === "/login" || path === "/contact") return;

        if (BACK_KEYS.includes(String(key))) {
            history.goBack();
        }
    }

    useEventListener("keydown", handler);

    return (
        <Switch>
            <RouteWithLayout
                layout={Main}
                exact
                path="/disclaimer"
                component={Disclaimer}
            />
            <PrivateRouteWithLayout
                layout={Main}
                exact
                path="/legal-structures"
                component={LegalStructures}
            />
            <PrivateRouteWithLayout
                layout={Main}
                exact
                path="/deal-taxes"
                component={DealTaxes}
            />
            <PrivateRouteWithLayout
                layout={MainWithFooter}
                exact
                path="/comparison-table"
                component={ComparisonTable}
            />
            <PrivateRouteWithLayout
                layout={Main}
                exact
                path="/tax-review"
                component={DealTaxesExpanded}
            />

            <Redirect to={"/disclaimer"} />
        </Switch>
    );
};

export default AppRouter;
