import React from "react";

import styles from "./formats-styles.module.css";

const DaysFormat = (d) => {
    const num = parseInt(d);
    if (!d || (d && isNaN(num))) return "-";

    if (num > 5) {
        return (
            <div className={styles.points}>
                <div /> <span> x {num} </span>
            </div>
        );
    }

    let items = [];
    for (let i = 0; i < num; i++) {
        items.push(<div />);
    }

    return (
        <div className={styles.points}>
            {items.map((d, index) => (
                <React.Fragment key={index}>{d}</React.Fragment>
            ))}
        </div>
    );
};
export default DaysFormat;
