const PeopleFormat = (d) => {
    const num = parseInt(d);
    if (!d || (d && isNaN(num))) return "-";

    let items = [];
    for (let i = 0; i < num; i++) {
        items.push("/images/user.svg");
    }

    return items.map((src, index) => <img key={index} alt={""} src={src} />);
};

export default PeopleFormat;
