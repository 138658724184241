import styles from "./button-styles.module.css";

const Button = ({ label, type, outlined, disabled, onClick }) => {
    return (
        <button
            className={`${outlined ? styles.outlined : ""}`}
            type={type}
            disabled={disabled}
            onClick={onClick}
        >
            {label}
        </button>
    );
};

export default Button;
