import styles from "./deal-taxes-styles.module.css";
import DealTaxesDetailRow from "./DealTaxesDetailRow";
import { CloseButton } from "../Button";

const DealTaxesDetail = ({ activeCategory, tips, onClose, legendChange }) => {
    const investment = tips.filter((t) => t.category === "investment");
    const holding = tips.filter((t) => t.category === "holding");
    const distribution = tips.filter((t) => t.category === "distribution");
    const selling = tips.filter((t) => t.category === "selling");

    return (
        <div className={styles.box}>
            <CloseButton onClick={() => onClose()} />

            {investment.length > 0 && (
                <DealTaxesDetailRow
                    rowTitle={"Investment"}
                    data={investment}
                    isActive={activeCategory === "investment" ?? true}
                    legendChange={legendChange}
                />
            )}

            {holding.length > 0 && (
                <DealTaxesDetailRow
                    rowTitle={"Holding"}
                    data={holding}
                    isActive={activeCategory === "holding" ?? true}
                    legendChange={legendChange}
                />
            )}

            {distribution.length > 0 && (
                <DealTaxesDetailRow
                    rowTitle={"Distribution"}
                    data={distribution}
                    isActive={activeCategory === "distribution" ?? true}
                    legendChange={legendChange}
                />
            )}

            {selling.length > 0 && (
                <DealTaxesDetailRow
                    rowTitle={"Selling"}
                    data={selling}
                    isActive={activeCategory === "selling" ?? true}
                    legendChange={legendChange}
                />
            )}
        </div>
    );
};

export default DealTaxesDetail;
