import { arc } from "d3-shape";
import { max, scaleLinear } from "d3";
import { path } from "d3-path";
import { getNodes, getRibbons } from "./calculateArchDiagram";
import { useEffect, useState } from "react";
import classes from "./arch-styles.module.css";

import React from "react";

const ArchDiagram = ({
    dataFull,
    onDataChange,
    activeCategory,
    // setActiveCategory,
}) => {
    const width = 800;
    const height = 884;
    const nodes = getNodes(dataFull, width, height);
    const ribbons = getRibbons(dataFull, nodes);

    const [prevActive, setPrevActive] = useState(null);

    // const [activeCategory, setActiveCategory] = useState(null);

    useEffect(() => {
        ribbons.map((r) => setDefaultOpacity(r));
        // setActiveCategory(null);
        onDataChange(null);
    }, [dataFull]);

    useEffect(() => {
        if (!activeCategory && prevActive !== activeCategory) {
            ribbons.map((r) => setDefaultOpacity(r));
        }

        setPrevActive(activeCategory);
    }, [activeCategory]);

    const arcGenerator = arc();
    const launchData = dataFull.map((d) => d.launchTiming);
    const launchBubbleScale = scaleLinear()
        .domain([0, max(launchData, (d) => d)])
        .range([25, 40]);

    const leftHalf = arcGenerator({
        innerRadius: 0,
        outerRadius: width / 2.2,
        startAngle: -Math.PI / 1,
        endAngle: 0,
    });

    const rightHalf = arcGenerator({
        innerRadius: 0,
        outerRadius: width / 2.2,
        startAngle: 0,
        endAngle: Math.PI / 1,
    });

    const leftText = path();
    leftText.arc(0, 0, 370, 1.32 * Math.PI, 1.5 * Math.PI);

    const rightText = path();
    rightText.arc(0, 0, 370, 1.55 * Math.PI, 1.8 * Math.PI);

    const setDefaultOpacity = (e) => {
        const els = e.from.ribbons.map((r) => document.getElementById(r.id));
        for (const el of els) {
            el.style.opacity = null;
            el.style.stroke = "none";
        }

        let groups = [];
        let bubbleGroups = [];
        for (const ribbon of ribbons) {
            const rg = `${ribbon.from.id}-${ribbon.to.id}-bubble`;
            const group1 = document.getElementsByClassName(ribbon.from.group);
            groups.push(...group1);
            const group2 = document.getElementsByClassName(ribbon.to.group);
            groups.push(...group2);
            const bg = document.getElementsByClassName(rg);
            bubbleGroups.push(...bg);
        }

        groups.map((g) => (g.style.opacity = null));
        bubbleGroups.map((g) => (g.style.opacity = null));
    };

    const onLinkEnter = (e) => {
        const els = e.from.ribbons.map((r) => document.getElementById(r.id));
        for (const el of els) {
            el.style.opacity = 1;
            el.style.stroke = "#fff";
        }

        let groups = [];
        let bubbleGroups = [];
        const nodeBubblesGroups = e.from.ribbons.map(
            (r) => `${r.from.id}-${r.to.id}-bubble`
        );
        const nodeNames = e.from.ribbons.map((r) => r.to.name);
        for (const ribbon of ribbons) {
            const rg = `${ribbon.from.id}-${ribbon.to.id}-bubble`;
            if (!nodeBubblesGroups.includes(rg)) {
                const bg = document.getElementsByClassName(rg);
                bubbleGroups.push(...bg);
            }

            if (ribbon.from.group !== e.from.group) {
                const group = document.getElementsByClassName(
                    ribbon.from.group
                );
                groups.push(...group);
            }

            // if (!nodeNames.includes(ribbon.to.name)) {
            //     const group = document.getElementsByClassName(ribbon.to.group);
            //     groups.push(...group);
            // }
        }

        groups.map((g) => {
            if (g.nodeName === "text") {
                g.style.opacity = 0.5;
            } else {
                g.style.opacity = 0;
            }
        });
        bubbleGroups.map((g) => (g.style.opacity = 0));
    };

    const onLinkLeave = (e) => {
        if (activeCategory?.id === e.from.id) return;

        setDefaultOpacity(e);
    };

    const onClick = (e) => {
        if (activeCategory?.id === e.from.id) {
            setDefaultOpacity(e);
            // setActiveCategory(null);
            onDataChange(null);

            onLinkLeave(e);
        } else {
            if (activeCategory) {
                setDefaultOpacity(activeCategory.ribbons[0]);
            }
            onLinkEnter(e);

            // setActiveCategory(e.from);
            onDataChange(e.from);
        }
    };

    return (
        <>
            <svg
                width={width}
                height={height}
                viewBox={`0 0 ${width} ${height}`}
                className={classes.svg}
            >
                <g transform={`translate(${width / 2}, ${height / 2})`}>
                    <path d={leftHalf} fill={"#1650B4"} opacity={0.06} />
                    <path d={rightHalf} fill={"#FE6F00"} opacity={0.06} />

                    <g>
                        <path id={`left-text`} d={leftText} fill={"none"} />

                        <path id={`right-text`} d={rightText} fill={"none"} />

                        <text
                            fontWeight={500}
                            fontSize={18}
                            fill={"#1650B4"}
                            opacity={0.6}
                        >
                            <textPath href={`#left-text`}>
                                INVESTMENT FUND
                            </textPath>
                        </text>

                        <text
                            fontWeight={500}
                            fontSize={18}
                            fill={"#FE6F00"}
                            opacity={0.6}
                        >
                            <textPath href={`#right-text`}>LEGAL FORM</textPath>
                        </text>
                    </g>

                    {nodes.map((r, key) => (
                        <React.Fragment key={key}>
                            {r.costToLaunchBubble && (
                                <g className={r.group} key={`${key}-g`}>
                                    <circle
                                        cx={r.costToLaunchBubble.x}
                                        cy={r.costToLaunchBubble.y}
                                        r={20 + r.costToLaunchBubble.offset}
                                        fill={"white"}
                                        strokeWidth={1.5}
                                        stroke={"#1650B4"}
                                    />
                                    <text
                                        fontWeight={700}
                                        fontSize={12}
                                        textAnchor={"middle"}
                                        x={r.costToLaunchBubble.textPosition.x}
                                        y={
                                            r.costToLaunchBubble.textPosition
                                                .y + 4
                                        }
                                    >
                                        {r.costToLaunchBubble.cost}
                                    </text>
                                </g>
                            )}
                        </React.Fragment>
                    ))}

                    {nodes.map((r, key) => (
                        <React.Fragment key={key}>
                            {r.dot && (
                                <g className={r.group}>
                                    <circle
                                        cx={r.dot.x}
                                        cy={r.dot.y}
                                        r={5}
                                        fill={r.dot.color}
                                    />
                                    <text
                                        fontWeight={700}
                                        fontSize={14}
                                        x={r.dot.x - 70}
                                        y={r.dot.y + 5}
                                    >
                                        {r.dot.cost}
                                    </text>
                                </g>
                            )}
                        </React.Fragment>
                    ))}

                    {nodes.map((r, key) => (
                        <React.Fragment key={key}>
                            {r.launchBubbles &&
                                r.launchBubbles.map((b, bKey) => (
                                    <g className={b.group} key={bKey}>
                                        <circle
                                            cx={b.x}
                                            cy={b.y}
                                            r={launchBubbleScale(b.cost)}
                                            fill={b.color}
                                            opacity={b.opacity}
                                        />
                                    </g>
                                ))}
                        </React.Fragment>
                    ))}

                    {nodes.map((r, key) => (
                        <g className={r.group} key={key}>
                            <path
                                key={`${r.id}-shadow`}
                                d={r.shadowPath}
                                fill={r.color}
                                opacity={r.shadowPathOpacity}
                            />
                        </g>
                    ))}

                    <circle r={260} fill={"white"} />

                    {nodes.map((r, key) => (
                        <React.Fragment key={key}>
                            {r.launchBubbles &&
                                r.launchBubbles.map((b, bKey) => (
                                    <g className={b.group} key={bKey}>
                                        <circle
                                            cx={b.x}
                                            cy={b.y}
                                            r={3}
                                            fill={b.color}
                                        />
                                        <text
                                            textAnchor={"middle"}
                                            fontWeight={700}
                                            fontSize={12}
                                            x={b.textPosition.x}
                                            y={b.textPosition.y + 5}
                                        >
                                            {b.cost}
                                        </text>
                                    </g>
                                ))}
                        </React.Fragment>
                    ))}

                    {ribbons.map((r) => (
                        <path
                            id={r.id}
                            key={r.id}
                            d={r.path}
                            fill={"#1650B4"}
                            opacity={0.06}
                            // onMouseEnter={() => onLinkEnter(r)}
                            // onMouseLeave={() => onLinkLeave(r)}
                            onClick={() => onClick(r)}
                        />
                    ))}

                    {nodes.map((r) => (
                        <g key={`${r.id}-g`}>
                            <path
                                key={r.id}
                                d={r.path}
                                fill={r.color}
                                onClick={() => onClick(r.ribbons[0])}
                            />
                            <path
                                id={`${r.id}-text`}
                                key={`${r.id}-text`}
                                d={r.textPath}
                                fill={"none"}
                            />

                            <text
                                className={r.group}
                                fontWeight={700}
                                fontSize={12}
                                cursor={"default"}
                                onClick={() => onClick(r.ribbons[0])}
                            >
                                <textPath href={`#${r.id}-text`}>
                                    {r.text.trim().toUpperCase()}
                                </textPath>
                            </text>

                            {r.regulationComplexityPosition && (
                                <g
                                    className={r.group}
                                    transform="translate(-9, -9)"
                                >
                                    <rect
                                        x={r.regulationComplexityPosition?.x}
                                        y={r.regulationComplexityPosition?.y}
                                        width={18}
                                        height={18}
                                        fill={"white"}
                                        stroke={"black"}
                                    />

                                    <text
                                        x={
                                            r.regulationComplexityPosition?.x +
                                            6
                                        }
                                        y={
                                            r.regulationComplexityPosition?.y +
                                            13
                                        }
                                        fontSize={14}
                                    >
                                        {r.regulationComplexity}
                                    </text>
                                </g>
                            )}
                        </g>
                    ))}
                </g>
            </svg>
        </>
    );
};

export default ArchDiagram;
