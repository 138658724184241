import ComparisonTableSection from "../ComparisonTableSection";
import TableHeader from "../components/TableHeader";
import { Tr } from "../components";

import { TypeOfSecurityFormat } from "../formats";

import { useSelector } from "react-redux";
import TrProvider from "../components/TrProvider";
import ComparisonTableSectionProvider from "../ComparisonTableSectionProvider";

const SD6 = ({
    triggerCollapse,
    setTablesCollapsed,
    tablesCollapsed,
    collapseOnClick,
}) => {
    const data = useSelector((state) => state.table.data);

    return data && data.sd6 ? (
        <ComparisonTableSectionProvider>
            <ComparisonTableSection
                triggerCollapse={triggerCollapse}
                setTablesCollapsed={setTablesCollapsed}
                tablesCollapsed={tablesCollapsed}
                collapseOnClick={collapseOnClick}
                title={"Form of participation"}
            >
                <table>
                    <TableHeader sdKey={"sd6"} />

                    <tbody>
                        <Tr
                            featName={"Legal form"}
                            dataKey={"sd6"}
                            colKey={"legalForm"}
                        />

                        <Tr
                            featName={"Form of securities"}
                            dataKey={"sd6"}
                            colKey={"formOfSecurities"}
                        />

                        <Tr
                            featName={"Form of contribution"}
                            dataKey={"sd7"}
                            colKey={"formOfContribution"}
                            fundKey={"sd6"}
                        />

                        <Tr
                            featName={"Participation form"}
                            dataKey={"sd8"}
                            fundKey={"sd6"}
                            colKey={"participationForm"}
                        />
                        <TrProvider>
                            <Tr
                                featName={"Type of securities: equity"}
                                dataKey={"sd8"}
                                fundKey={"sd6"}
                                colKey={"typeOfSecurities"}
                                formatFunc={TypeOfSecurityFormat}
                                formatData={{
                                    securityType: "equity",
                                }}
                            />
                        </TrProvider>

                        <TrProvider>
                            <Tr
                                featName={"Type of securities: debt"}
                                dataKey={"sd8"}
                                fundKey={"sd6"}
                                colKey={"typeOfSecurities"}
                                formatFunc={TypeOfSecurityFormat}
                                formatData={{
                                    securityType: "debt",
                                }}
                            />
                        </TrProvider>

                        <TrProvider>
                            {" "}
                            <Tr
                                featName={"Type of securities: hybrid"}
                                dataKey={"sd8"}
                                fundKey={"sd6"}
                                colKey={"typeOfSecurities"}
                                formatFunc={TypeOfSecurityFormat}
                                formatData={{
                                    securityType: "hybrid",
                                }}
                            />
                        </TrProvider>
                    </tbody>
                </table>
            </ComparisonTableSection>
        </ComparisonTableSectionProvider>
    ) : null;
};

export default SD6;
