import React, { useEffect, useState } from "react";

import styles from "./comparison-table-styles.module.css";
import { useSelector } from "react-redux";
import SD14 from "./tables/SD14";
import SD13 from "./tables/SD13";

const ComparisonTableHightlighted = ({
    triggerCollapse,
    setTablesCollapsed,
    tablesCollapsed,
    collapseOnClick,
}) => {
    const data = useSelector((state) => state.table.data);

    const [initCollapsed, setInitCollapsed] = useState(0);

    useEffect(() => {
        if (tablesCollapsed === 0) {
            setInitCollapsed(0);
        }
    }, [tablesCollapsed]);

    return (
        data && (
            <div
                className={`${styles.hightlightedWrapperDefault} ${
                    initCollapsed > 0 ? styles.activeWrapper : ""
                }`}
            >
                <div className={styles.container}>
                    <SD13
                        triggerCollapse={triggerCollapse}
                        setTablesCollapsed={setTablesCollapsed}
                        tablesCollapsed={tablesCollapsed}
                        collapseOnClick={collapseOnClick}
                        setInitCollapsed={setInitCollapsed}
                        initCollapsed={initCollapsed}
                    />

                    <SD14
                        triggerCollapse={triggerCollapse}
                        setTablesCollapsed={setTablesCollapsed}
                        tablesCollapsed={tablesCollapsed}
                        collapseOnClick={collapseOnClick}
                        setInitCollapsed={setInitCollapsed}
                        initCollapsed={initCollapsed}
                    />

                    {initCollapsed > 0 && (
                        <p>* not required, but standard service providers</p>
                    )}
                </div>
            </div>
        )
    );
};

export default ComparisonTableHightlighted;
