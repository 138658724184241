import styles from "./aside-styles.module.css";
import { Link, NavLink, useHistory } from "react-router-dom";
import { useContext } from "react";
import { DisclaimerContext } from "../../../context";
import axios from "../../../utils/axios";
import { CSSTransition } from "react-transition-group";

const Aside = ({ active, onStateChange }) => {
    const { disclaimer, setDisclaimer } = useContext(DisclaimerContext);

    return (
        <>
            <button
                className={`${styles.hamburger} ${active ? styles.active : ""}`}
                onClick={onStateChange}
            >
                <span />
            </button>

            <CSSTransition
                in={active}
                timeout={400}
                classNames={{
                    appear: styles.fadeAnimation,
                    appearActive: styles.fadeAnimationActive,
                    appearDone: styles.fadeAnimationActive,
                    enter: styles.fadeAnimation,
                    enterActive: styles.fadeAnimationActive,
                    enterDone: styles.fadeAnimationActive,
                    exit: styles.fadeAnimation,
                    exitActive: styles.fadeAnimation,
                    exitDone: styles.fadeAnimation,
                }}
                unmountOnExit
            >
                <div className={`${styles.wrapper}`} onClick={onStateChange}>
                    <aside className={styles.aside}>
                        <Link to={"/"}>
                            <img src={"/logo-white.svg"} alt={"White Thesis"} />
                        </Link>

                        <nav>
                            <NavLink
                                to={"/deal-taxes"}
                                className={`${
                                    !disclaimer ? styles.disabled : ""
                                }`}
                            >
                                Deal taxes
                            </NavLink>
                            <NavLink
                                to={"/legal-structures"}
                                className={`${
                                    !disclaimer ? styles.disabled : ""
                                }`}
                            >
                                Legal structures
                            </NavLink>
                            <NavLink
                                to={"/contact"}
                                className={`${
                                    !disclaimer ? styles.disabled : ""
                                }`}
                            >
                                Disclaimer
                            </NavLink>
                            <a
                                href={`${process.env.REACT_APP_LANDING_URL}?page=contact-us`}
                            >
                                Contact us
                            </a>
                        </nav>
                    </aside>
                </div>
            </CSSTransition>
        </>
    );
};

export default Aside;
