const TypeOfSecurityFormat = (d, { type }) => {
    const trimSecurityStr = (d, type) => {
        const types = ["debt:", "equity:", "hybrid:"];
        const start = d.indexOf(type);
        let end = 0;
        for (const t of types) {
            if (d.indexOf(t) > -1 && t !== type) {
                end = d.indexOf(t);
            }
        }
        end = start > end ? d.length : end;

        if (start >= end) return d;

        return d.slice(start, end);
    };

    if (!d) return "-";
    d = d.toString().trim();

    type += ":";
    d = trimSecurityStr(d, type);
    d = trimSecurityStr(d, type);

    return d.slice(type.length, d.length);
};

export default TypeOfSecurityFormat;
