import { DescriptionFormat, ProgressBar } from "./index";

const DescriptionProgressFormat = (d, { progressVal, descriptionVal }) => {
    const desc = DescriptionFormat(d, { descriptionVal });
    const progress = ProgressBar(progressVal);

    return (
        <>
            {progress}
            {desc}
        </>
    );
};

export default DescriptionProgressFormat;
