import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { useSelector } from "react-redux";

const MainWithFooter = (props) => {
    const { children } = props;

    const showFooter = useSelector((state) => state.footer.showFooter);

    return (
        <>
            <Header />

            <main>{children}</main>

            {showFooter && <Footer />}
        </>
    );
};

export default MainWithFooter;
