const OpenClosedFormat = (d) => {
    if (d === null || typeof d === "undefined") return "-";
    d = d.toString();

    if (d === "true") return "Open-ended";
    if (d === "false") return "Closed-ended";

    const arr = d.split(",");

    if (arr.length > 1) return "Both";
};

export default OpenClosedFormat;
