import React from "react";

export const TableDescriptionContext = React.createContext({
    expandedRows: 0,
    setExpandedRows: () => {},
    globalExpanded: false,
    setGlobalExpanded: () => {},
    totalExpandableRows: 0,
    setTotalExpandableRows: () => {},
});
