import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,
    activeFund: null,
    collapsedTables: 0,
    triggerCollapse: false,
};

export const tableSlice = createSlice({
    name: "table",
    initialState,
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        },
        setActiveFund: (state, action) => {
            state.activeFund =
                state.activeFund === action.payload ? null : action.payload;
        },
        collapseTable: (state) => {
            state.collapsedTables += 1;
        },
        openTable: (state) => {
            state.collapsedTables -= 1;
        },
        setTriggerCollapse: (state, action) => {
            state.triggerCollapse = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setData,
    setActiveFund,
    collapseTable,
    openTable,
    setTriggerCollapse,
} = tableSlice.actions;

export default tableSlice.reducer;
