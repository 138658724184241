import { Link, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";

import styles from "./legal-structures-styles.module.css";
import axios from "../../utils/axios";

import { Checkbox } from "../../shared/Checkbox";
import { SelectionPopup } from "../../shared/SelectionPopup";
import ResultTableLeft from "../../shared/ResultTable/ResultTableLeft";
import ResultTableRight from "../../shared/ResultTable/ResultTableRight";
import { ArchDiagram } from "../../shared/ArchDiagram";
import { MainStructuresDetail } from "../../shared/MainStructuresDetail";
import { ResultTable } from "../../shared/ResultTable";

const LegalStructures = () => {
    const [selectedData, setSelectedData] = useState({
        investor: "",
        asset: "",
        selfManaged: false,
        noFixedCapital: true,
        openEnded: false,
        corporate: false,
    });

    const [openEnded, setOpenEnded] = useState(null);

    const [investors, setInvestors] = useState([]);
    const [assets, setAssets] = useState([]);
    const [assetClass, setAssetClass] = useState(null);
    const [investor, setInvestor] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [archDiagramData, setArchDiagramData] = useState(null);
    const [activeInvestment, setActiveInvestment] = useState(null);

    const [checkClicked, setCheckClicked] = useState(false);
    const [toggleRequest, setToggleRequest] = useState(false);

    const history = useHistory();

    const [tableUrl, setTableUrl] = useState("");

    useEffect(() => {
        setTableUrl(
            `?investorId=${selectedData.investor}&investorName=${investor}&assetClass=${assetClass}&selfManaged=${selectedData.selfManaged}&noFixedCapital=${selectedData.noFixedCapital}&corporate=${selectedData.corporate}&openEnded=${selectedData.openEnded}`
        );
    }, [selectedData]);

    useEffect(() => {
        const params = new URLSearchParams(history.location.search);

        if (params.has("back")) {
            const investor = params.get("investorId");
            const asset = params.get("asset");

            if (!investor && !asset) {
                setShowPopup(true);
                return;
            }

            const selfManaged = params.get("selfManaged");
            const noFixedCapital = params.get("noFixedCapital");
            const corporate = params.get("corporate");
            const openEnded = params.get("openEnded");

            setAssetClass(params.get("assetClass"));
            setInvestor(params.get("investorName"));

            setSelectedData({
                ...selectedData,
                investor,
                asset,
                selfManaged: selfManaged === "true" ? true : false,
                noFixedCapital: noFixedCapital === "true" ? true : false,
                corporate: corporate === "true" ? true : false,
                openEnded: openEnded === "true" ? true : false,
            });

            setCheckClicked(true);
        } else {
            setShowPopup(true);
        }
    }, []);

    useEffect(() => {
        return history.listen((location) => {
            const params = new URLSearchParams(location.search);

            const investor = params.get("investorId");
            const asset = params.get("assetClass");

            if (!investor || !asset) {
                setShowPopup(true);
                return;
            }

            setSelectedData({
                ...selectedData,
                investor,
                asset,
            });
        });
    }, [history]);

    useEffect(() => {
        if (toggleRequest) {
            (async () => await getArchDiagramData(true))();
        }
    }, [toggleRequest]);

    useEffect(() => {
        if (checkClicked) {
            (async () => await getArchDiagramData(false))();
        }
    }, [
        checkClicked,
        selectedData.selfManaged,
        selectedData.noFixedCapital,
        selectedData.openEnded,
        selectedData.corporate,
    ]);

    const handleCheckbox = (prop) => async (event) => {
        setCheckClicked(true);

        setSelectedData({ ...selectedData, [prop]: event.target.checked });
    };

    const handleFormSubmission = async (formSelectedData, data) => {
        setInvestors(data.investors);
        setAssets(data.assets);
        setOpenEnded(data.openEnded);
        setAssetClass(
            data.assets.find((a) => a.assetId == formSelectedData.asset)
                ?.assetClass
        );
        setInvestor(
            data.investors.find(
                (i) => i.investorId == formSelectedData.investor
            )?.investor
        );

        const tmp = { ...selectedData, ...formSelectedData };

        setSelectedData({ ...tmp, selfManaged: false, noFixedCapital: true });

        setShowPopup(false);

        setToggleRequest(true);
    };

    const getArchDiagramData = async (toggleCheckboxes) => {
        setCheckClicked(false);
        setToggleRequest(false);

        let isCorporate = selectedData.corporate;
        let isOpenEnded = selectedData.openEnded;

        if (toggleCheckboxes) {
            isCorporate = openEnded.legalType.trim() === "corporate";
            isOpenEnded = openEnded.ifRecommendedOpenEnded;

            setSelectedData({
                ...selectedData,
                corporate: isCorporate,
                openEnded: isOpenEnded,
            });
        }

        const data = await axios.get("/demo/arch", {
            params: {
                selfManaged: selectedData.selfManaged,
                noFixedCapital: selectedData.noFixedCapital,
                openEnded: isOpenEnded,
                corporate: isCorporate,
            },
        });

        setArchDiagramData(data.data);
    };

    const onDataChange = (investment) => {
        setActiveInvestment(investment);
        if (investment) {
            setShowDetails(true);
        } else {
            setShowDetails(false);
            setActiveInvestment(null);
        }
    };

    const closeDetails = () => {
        setShowDetails(false);
        setActiveInvestment(null);
    };

    const Info = () => {
        return (
            <>
                <h3 className={styles.info}>
                    Best-fit{" "}
                    <span className={styles.highlight}>legal structures</span> :
                </h3>
            </>
        );
    };

    const Tips = () => {
        return (
            <>
                <div className={`${styles.graphTip} ${styles.initial}`}>
                    Cost to launch <span className={styles.footnote}>1</span>
                </div>
                <div className={`${styles.graphTip} ${styles.nav}`}>
                    Annual cost for $100m{" "}
                    <span className={styles.footnote}>1</span>
                </div>
                <div className={`${styles.graphTip} ${styles.taxed}`}>
                    Complexity
                </div>

                <div className={`${styles.graphTip} ${styles.days}`}>
                    Days to launch <span className={styles.footnote}>2</span>
                </div>
            </>
        );
    };

    const AdditionalChecks = ({ handleCheckbox }) => {
        return (
            <>
                <Checkbox
                    label={"self - managed"}
                    value={true}
                    checked={selectedData.selfManaged}
                    onChange={handleCheckbox("selfManaged")}
                    info={`A collective investment scheme (CIS) should be deemed self-managed when the management functions are performed by the governing body or any internal resource of the CIS.`}
                />
                <Checkbox
                    label={"no fixed capital"}
                    value={true}
                    checked={selectedData.noFixedCapital}
                    onChange={handleCheckbox("noFixedCapital")}
                    info={`It’s a type of investment fund in Luxembourg that has a fixed capital. Its share capital can only be increased by the company’s shareholders (via amendment of the constitutional documents) and any changes to the capital must be notified and published.`}
                />
                <Checkbox
                    label={"open-ended"}
                    value={true}
                    checked={selectedData.openEnded}
                    onChange={handleCheckbox("openEnded")}
                    info={`Collective investment scheme whose investors have a legal right to redeem or withdraw their shares or interest at least once per year.`}
                />
                <Checkbox
                    label={"corporate"}
                    value={true}
                    checked={selectedData.corporate}
                    onChange={handleCheckbox("corporate")}
                    info={
                        <>
                            A corporation is an independent legal entity owned
                            by its shareholders (Lux: Sàrl, SA, SCA; Cayman:
                            company, LLC, SPC).
                            <br />
                            Whereas a limited partnership is a partnership that
                            is established by contract between one or more
                            unlimited partners (or general partners) and one or
                            more limited partners (Lux: SCS, SCSp, SCA; Cayman:
                            LP).
                        </>
                    }
                />
            </>
        );
    };

    return (
        <>
            {showPopup && (
                <SelectionPopup
                    onClose={() => setShowPopup(false)}
                    onSubmit={handleFormSubmission}
                    useInvestmentStructure={false}
                />
            )}

            <ResultTable>
                <ResultTableLeft
                    tableName={"Legal structures"}
                    tableInfo={<Info />}
                    legend={true}
                    investor={investor}
                    asset={assetClass}
                    onEdit={() => {
                        setShowPopup(true);
                    }}
                />

                <div className={styles.graph}>
                    {archDiagramData && (
                        <>
                            <ArchDiagram
                                dataFull={archDiagramData}
                                onDataChange={onDataChange}
                                activeCategory={activeInvestment}
                            />

                            <Link
                                to={{
                                    pathname: "/comparison-table",
                                    search: tableUrl,
                                }}
                                className={
                                    !archDiagramData ||
                                    (archDiagramData &&
                                        archDiagramData.length === 0)
                                        ? styles.disabledLink
                                        : ""
                                }
                            >
                                Learn more
                            </Link>
                        </>
                    )}
                </div>

                <ResultTableRight
                    showDetails={showDetails}
                    details={
                        <MainStructuresDetail
                            activeInvestment={activeInvestment}
                            onClose={closeDetails}
                        />
                    }
                    checkboxes={
                        <AdditionalChecks handleCheckbox={handleCheckbox} />
                    }
                    tips={<Tips />}
                    exploreText={`Click on the visualization to explore the link between investment funds and legal forms`}
                />
            </ResultTable>
        </>
    );
};

export default LegalStructures;
