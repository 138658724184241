import ComparisonTableSection from "../ComparisonTableSection";
import TableHeader from "../components/TableHeader";
import { Tr } from "../components";

import { BooleanFormat, DescriptionProgressFormat } from "../formats";

import { useSelector } from "react-redux";
import TrProvider from "../components/TrProvider";
import ComparisonTableSectionProvider from "../ComparisonTableSectionProvider";

const SD10 = ({
    triggerCollapse,
    setTablesCollapsed,
    tablesCollapsed,
    collapseOnClick,
}) => {
    const data = useSelector((state) => state.table.data);

    return data && data.sd10 ? (
        <ComparisonTableSectionProvider>
            <ComparisonTableSection
                triggerCollapse={triggerCollapse}
                setTablesCollapsed={setTablesCollapsed}
                tablesCollapsed={tablesCollapsed}
                collapseOnClick={collapseOnClick}
                title={"Privacy"}
            >
                <table>
                    <TableHeader sdKey={"sd10"} />

                    <tbody>
                        <Tr
                            featName={"Legal form"}
                            dataKey={"sd10"}
                            colKey={"legalForm"}
                        />
                        <Tr
                            featName={
                                "Disclosure of ultimate beneficial ownership"
                            }
                            dataKey={"sd10"}
                            colKey={"disclosureOfUbo"}
                            formatFunc={BooleanFormat}
                        />
                        <Tr
                            featName={"Disclosure of shareholders identity"}
                            dataKey={"sd10"}
                            colKey={"disclosureOfShareholdersIdentity"}
                            formatFunc={BooleanFormat}
                        />
                        <TrProvider>
                            <Tr
                                featName={"Public dislosures"}
                                dataKey={"sd10"}
                                colKey={"publicDislosures"}
                                formatFunc={DescriptionProgressFormat}
                                progressCol={"publicDisclosuresBar"}
                            />
                        </TrProvider>
                    </tbody>
                </table>
            </ComparisonTableSection>
        </ComparisonTableSectionProvider>
    ) : null;
};

export default SD10;
